import "./WelcomeScreen.css";

import heroImage from "../../../assets/charley_wave_full.svg";
import {ImageUtil} from "../../../util/ImageUtil";
import {ThemedButton} from "../../ui/ThemedButton";
import {Navigator} from "../../../navigator/Navigator";
import {ChatLandingScreen} from "../chat/ChatLandingScreen";
import {AppConfig} from "../../../util/AppConfig";
import Rosetta from "../../../rosetta/Rosetta";
import {API} from "../../../network/API";
import {CommonUtil} from "../../../util/CommonUtil";
import {DataManager} from "../../../util/DataManager";

export const WelcomeScreen = (props) => {

    let welcomeImageElem = [];
    if (parseInt(AppConfig.getConfig(AppConfig.keys.WELCOME_SCREEN_IMAGE_SHOWN, 1)) === 1) {
        const welcomeScreenImage = AppConfig.getConfig(AppConfig.keys.WELCOME_SCREEN_ASSISTANT_IMAGE_URL, heroImage);
        welcomeImageElem = (
            <div
                className={"welcome-image"}
                style={{backgroundImage: ImageUtil.background(welcomeScreenImage)}}
                tabIndex={0}
                aria-label={Rosetta.string("welcome.accessibility_hero_image")}
            />
        );
    }

    const messageElems = [];
    const message = AppConfig.getConfig(AppConfig.keys.WELCOME_SCREEN_MESSAGE, Rosetta.string("welcome.default_message"));
    message.split("\n").forEach((line) => {
        messageElems.push(<p>{line}</p>);
    });

    const buttonLabel = AppConfig.getConfig(AppConfig.keys.WELCOME_SCREEN_BUTTON_LABEL, Rosetta.string("welcome.default_button_label"));

    const termsAccessibilityString = [
        Rosetta.string("welcome.terms_template_1", { button_label : buttonLabel }),
        Rosetta.string("welcome.terms_template_2"),
        Rosetta.string("welcome.terms_template_3"),
        Rosetta.string("welcome.terms_template_4"),
    ].map((part, index) => {
        let out = part;
        if (index !== 0) {
            out = " " + out;
        }
        return out;
    })

    return (
        <div className={"welcome-screen"}>

            <div className={"spacer"}/>

            {welcomeImageElem}

            <div className={"welcome-message"}>
                {messageElems}
            </div>

            <div className={"welcome-button"}>
                <ThemedButton
                    label={buttonLabel}
                    onClick={() => {
                        DataManager.setWelcomeScreenPassed(true);

                        Navigator.navigate(
                            <ChatLandingScreen/>,
                            undefined,
                            true
                        );
                    }}
                />
            </div>

            <div className={"spacer"}/>

            <div className={"welcome-fineprint"}>
                <span tabIndex={0} aria-label={termsAccessibilityString} role={"presentation"}><span tabIndex={-1} aria-hidden={true}>{Rosetta.string("welcome.terms_template_1", { button_label : buttonLabel })}</span></span> &nbsp;
                <a href={API.getTermsUrl()} aria-label={Rosetta.string("welcome.accessibility_terms_link")} target={"_blank"} rel={"noreferrer"} tabIndex={0} onClick={CommonUtil.interceptLink}>{Rosetta.string("welcome.terms_template_2")}</a>
                <span tabIndex={-1} aria-hidden={true}>{Rosetta.string("welcome.terms_template_3")}</span>
                <a href={API.getPrivacyPolicyUrl()} aria-label={Rosetta.string("welcome.accessibility_privacy_link")} target={"_blank"} rel={"noreferrer"} tabIndex={0} onClick={CommonUtil.interceptLink}>{Rosetta.string("welcome.terms_template_4")}</a>
            </div>

        </div>
    )

}