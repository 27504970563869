import {DataManager} from "./DataManager";
import {CommonUtil} from "./CommonUtil";
import {AppConfig} from "./AppConfig";
import axios from "axios";
import {API, ENDPOINTS} from "../network/API";
import {ServiceUtil} from "./ServiceUtil";
import {Navigator} from "../navigator/Navigator";
import {SupportListScreen} from "../components/screens/support/SupportListScreen";
import {TimeUtil} from "./TimeUtil";

export const SupportPageUtil = {
    _temporarySupportData : undefined,
    types : {
        SUPPORT : 1,
        MONEY : 2,
        I_NEED_HELP : 3,
        ASSISTANT_SUPPORT : 4,
        ASSISTANT_NEED_HELP : 5
    },
    /**
     * Determines if the device has valid stored Location data
     * and returns the Campus ID of the Location if so
     * @returns {*|null}    Campus ID or null
     */
    getCachedLocationID : () => {
        const roleCampusData = DataManager.getRoleCampusData();
        if (roleCampusData) {
            let hasConfiguredLocation = false;
            if (DataManager.isUsingLocalStorage()) {
                // If we are using persistent storage, do not check expiry.
                hasConfiguredLocation = true;
            } else {
                if (roleCampusData.hasOwnProperty("expires")) {
                    const now = new Date().getTime();
                    if (parseInt(roleCampusData.expires) > now) {
                        hasConfiguredLocation = true;
                    }
                }
            }

            if (hasConfiguredLocation) {
                const campus = CommonUtil.getOrDefault(roleCampusData, "campus", null);
                if (campus) {
                    return campus.id;
                }
            }
        }

        return null;
    },
    assignRoleLocationOverNetwork : (campusID, callback) => {
        const roleID = AppConfig.getConfig(AppConfig.keys.DEFAULT_USER_ROLE_ID, 0);

        const data = {
            roleID,
            campusID
        };

        axios.post(ENDPOINTS.user.updateUserRoleCampus, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    DataManager.setRoleConfigured(true);
                    if (callback) {
                        callback("finished")
                    }
                } else {
                    console.log(API.formatError(resp));
                    if (callback) {
                        callback("error", API.formatError(resp));
                    }
                }

            })
            .catch((e) => {
                console.log(e);
                callback("exception", e);
            });
    },
    populateLocationIntoCache : (location) => {
        let roleCampusData = DataManager.getRoleCampusData();

        const now = new Date().getTime();
        const tfh = (TimeUtil.HOUR * 1000) * 24; // HOUR is seconds, require milliseconds
        const expires = now + tfh;

        if (!roleCampusData) {
            roleCampusData = {
                role : null,
                campus : null,
                expires
            };
        }

        if (location) {
            roleCampusData.campus = location;
        }

        roleCampusData.expires = expires;

        DataManager.setRoleCampusData(roleCampusData);
    }
}