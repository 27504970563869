import "./MinimalSeekBar.css";
import {useEffect, useRef, useState} from "react";
import Rosetta from "../../rosetta/Rosetta";

export const MinimalSeekBarActions = {
    CHANGE : "change"
};

export const MinimalSeekBarCallbackStrategies = {
    /** Default. Will only trigger a callback when the user releases the drag handle. **/
    ON_UP : "mscs.on_up",
    /** Will trigger a callback while the user is dragging on the seek bar. **/
    ON_MOVE : "mscs.move"
};

export const MinimalSeekBar = (props) => {

    const {progress} = props;
    const {max} = props;
    const {min} = props;
    const {barColour} = props;
    const {handleColour} = props;
    const {callbackStrategy} = props;
    const {callback} = props;

    const seekRoot = useRef();

    const [seeking, setSeeking] = useState(false);
    const [seekProgress, setSeekProgress] = useState(0);
    const [progressChanged, setProgressChanged] = useState(true);

    const [windowMouseUp, setWindowMouseUp] = useState(false);

    const windowMouseUpCallback = () => {
        setWindowMouseUp(true);
    }

    useEffect(() => {
        // Mobile Safari - Need to handle this specially from other events as Mobile Safari
        // explicitly handles touch events as passive, which causes the page to scroll while
        // touching, which due to the bounce-and-return behaviour of Safari pages is really
        // jarring when you touch and move vertically at all while dragging.
        if (seekRoot.current) {
            seekRoot.current.addEventListener("touchmove", updateDragging, {passive: false});
        }

        window.addEventListener("mouseup", windowMouseUpCallback);

        return () => {
            if (seekRoot.current) {
                seekRoot.current.removeEventListener("touchmove", updateDragging, {passive: false});
            }

            window.removeEventListener("mouseup", windowMouseUpCallback);
        }
    }, []);

    useEffect(() => {
        setProgressChanged(true);
    }, [progress]);

    useEffect(() => {
        if (!callbackStrategy || callbackStrategy === MinimalSeekBarCallbackStrategies.ON_UP) {
            if (!seeking) {
                if (seekProgress >= 0) {
                    handleCallback(MinimalSeekBarActions.CHANGE, seekProgress);
                }
            }
        }
    }, [seeking]);

    useEffect(() => {
        if (seeking && callbackStrategy === MinimalSeekBarCallbackStrategies.ON_MOVE) {
            handleCallback(MinimalSeekBarActions.CHANGE, seekProgress);
        }
    }, [seekProgress]);

    useEffect(() => {
        if (windowMouseUp) {
            stopDragging();
            setWindowMouseUp(false);
        }
    }, [windowMouseUp]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function startDragging() {
        setSeeking(true);
    }

    function stopDragging() {
        setSeeking(false);
    }

    function updateDragging(e) {
        e.preventDefault();
        e.stopPropagation();

        if (seekRoot) {
            const boundingBox = seekRoot.current.getBoundingClientRect();
            let elemStart = boundingBox.left;
            let elemEnd = boundingBox.right - elemStart;
            let mouseX = 0;

            if (e.type === "mousemove") {
                if (seeking) {
                    mouseX = e.clientX - elemStart;
                } else {
                    return;
                }
            } else if (e.type === "touchmove") {
                const { touches, changedTouches } = e.originalEvent ?? e;
                const touchPoint = touches[0] ?? changedTouches[0];
                mouseX = touchPoint.pageX - elemStart;
            }

            setProgressChanged(false);

            if (mouseX > 0 && mouseX < elemEnd) {
                let newProgress = (mouseX / elemEnd) * 100;
                setSeekProgress(newProgress);
            }
        }
    }

    // RENDER

    let useMax = max ? max : 100;
    let useMin = min ? min : 0;

    let progressLeft = (progress / (useMax - useMin)) * 100;
    if (seeking || !progressChanged) {
        //console.log("Using seekProgress. " + seeking, progressChanged);
        progressLeft = seekProgress;
    }

    let barStyle = {};
    if (barColour) {
        barStyle.backgroundColor = barColour;
    }

    let handleStyle = {left: progressLeft + "%"};
    if (handleColour) {
        handleStyle.backgroundColor = handleColour;
    }

    // onMouseLeave={stopDragging}
    return (
        <div
            className={"minimal-seek-bar"}
            ref={seekRoot}
            tabIndex={0}
            aria-label={Rosetta.string("main.accessibility_seek_bar_progress", { percent : progressLeft })}
            role={"presentation"}
            onMouseDown={startDragging}
            onMouseUp={stopDragging}
            onMouseMove={updateDragging}
            onTouchStart={() => startDragging("touch")}
            onTouchCancel={() => stopDragging("touch")}
            onTouchEnd={stopDragging}
        >

            <div className={"seek-bar"} style={barStyle} />

            <div
                className={"seek-handle"}
                style={handleStyle}
            />

        </div>
    )

}