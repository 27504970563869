import {useEffect, useRef, useState} from "react";

import "./PodcastPlayerScreen.css";
import {MinimalSeekBar, MinimalSeekBarActions} from "../../ui/MinimalSeekBar";
import Rosetta from "../../../rosetta/Rosetta";
import {ImageUtil} from "../../../util/ImageUtil";
import {PodcastUtil} from "../../../util/PodcastUtil";
import {PodcastVolumeModal, PodcastVolumeModalActions} from "./PodcastVolumeModal";
import {CommonUtil} from "../../../util/CommonUtil";

import iconBack from "../../../assets/ic_back_15.svg";
import iconForward from "../../../assets/ic_forward_15.svg";
import iconPlay from "../../../assets/play.svg";
import iconPause from "../../../assets/pause.svg";
import iconVolume from "../../../assets/ic_listen.svg";
import iconShare from "../../../assets/share.svg";
import {API, ENDPOINTS} from "../../../network/API";
import axios from "axios";
import {TimeUtil} from "../../../util/TimeUtil";
import {Navigator} from "../../../navigator/Navigator";
import {PodcastFeedbackScreen} from "./PodcastFeedbackScreen";

export const PodcastPlayerScreen = (props) => {

    const {podcastId} = props;

    const podcastAudioElem = useRef();
    const backgroundAudioElem = useRef();

    const [podcast, setPodcast] = useState(null);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [mediaUrl, setMediaUrl] = useState(null);

    const [ready, setReady] = useState(false);
    const [buffering, setBuffering] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [playbackPosition, setPlaybackPosition] = useState(0);
    const [playbackDuration, setPlaybackDuration] = useState(0);

    const [volumeModalShown, setVolumeModalShown] = useState(false);
    const [overallVolume, setOverallVolume] = useState(1);
    const [podcastVolume, setPodcastVolume] = useState(1);
    const [backgroundVolume, setBackgroundVolume] = useState(0.6);

    const [completionSignalled, setCompletionSignalled] = useState(false);
    const [completionSignalNetworkInFlight, setCompletionSignalNetworkInFlight] = useState(false);

    const playbackInterval = useRef();

    useEffect(() => {
        playbackInterval.current = setInterval(() => {
            if (podcastAudioElem.current) {
                let position = podcastAudioElem.current.currentTime;
                if (isNaN(position)) {
                    position = 0;
                }
                setPlaybackPosition(position);

                // CADE 16/09/2024 - When seeking, the background audio would receive a call to "pause"
                // because the callbacks were being triggered correctly however mysteriously the audio would
                // continue to play even though only the "canplay" callback was triggered. As a result, the background
                // audio sync became dislodged. So now we check to see if the Podcast Audio is playing but the
                // background audio is paused and force the background audio to play. HTML5 Audio sucks.
                try {
                    if (backgroundAudioElem.current) {
                        if (backgroundAudioElem.current.paused && !podcastAudioElem.current.paused) {
                            backgroundAudioElem.current.play();
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        }, 100);

        if (podcastId) {
            fetchPodcastFromNetwork();
        }

        return () => {
            // Clear up position interval
            if (playbackInterval.current) {
                clearInterval(playbackInterval.current);
            }
        }
    }, []);

    useEffect(() => {
        if (podcast) {
            if (podcast.hasOwnProperty("audioFileURL")) {
                setMediaUrl(podcast.audioFileURL);
                // setMediaUrl("/audio/test_audio.mp3");
            }
        }
    }, [podcast]);

    useEffect(() => {
        if (playing) {
            if (podcastAudioElem.current) {
                podcastAudioElem.current.play();
            }
            try {
                if (backgroundAudioElem.current) {
                    backgroundAudioElem.current.play()
                        .catch((e) => {
                            console.log(e);
                        });
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            if (podcastAudioElem.current) {
                podcastAudioElem.current.pause();
            }

            try {
                if (backgroundAudioElem.current) {
                    backgroundAudioElem.current.pause();
                }
            } catch (e) {
                console.log(e);
            }
        }

        // Update volumes now
        updatePlayerVolume();
    }, [playing])

    useEffect(() => {
        if (backgroundAudioElem.current) {
            backgroundAudioElem.current.pause();
        }
    }, [buffering]);

    useEffect(() => {
        updatePlayerVolume();
    }, [overallVolume, podcastVolume, backgroundVolume]);

    function playPauseWasClicked() {
        if (!buffering && ready) {
            if (podcastAudioElem.current) {
                if (playing) {
                    podcastAudioElem.current.pause();
                } else {
                    podcastAudioElem.current.play();
                }
            }
        }
    }

    function mediaDidUpdate(action, data) {
        console.log("mediaDidUpdate " + action, data);
        if (action === "canplay") {
            // This can also be metadataloaded because Mobile Safari sucks.
            setReady(true);
            setBuffering(false);
        } else if (action === "ended") {
            setPlaying(false);
            if (PodcastUtil.hasCompletedFeedback(podcastId)) {
                // If we have previously submitted feedback, do not
                // show feedback form.
                logPodcastEventOverNetwork(true);
            } else {
                // If we have not submitted feedback, submit now.
                Navigator.navigate(
                    <PodcastFeedbackScreen
                        podcast={podcast}
                        listenTime={Math.ceil(playbackPosition).toFixed(0)}
                    />
                    , undefined, true);
            }
        } else if (action === "play") {
            setPlaying(true);
            setBuffering(false);
        } else if (action === "pause") {
            setPlaying(false);
            logPodcastEventOverNetwork(completionSignalled);
        } else if (action === "waiting") {
            setBuffering(true);
        } else if (action === "durationchange") {
            console.log("durationchange");
            if (podcastAudioElem.current) {
                let duration = podcastAudioElem.current.duration;
                if (isNaN(duration)) {
                    duration = 0;
                }

                setPlaybackDuration(duration);
            }
        }
    }

    function formatTime(timestamp) {
        const parts = [];

        if (playbackDuration > 3600) {
            // Only add hours if the total duration is > 1 hour
            let hours = parseInt(timestamp / 3600);
            if (hours > 0) {
                timestamp -= hours * 3600;
            }
            parts.push(hours);
        }

        let minutes = parseInt(timestamp / 60);
        if (minutes > 0) {
            timestamp -= minutes * 60;
        }
        parts.push(minutes);

        let seconds = parseInt(timestamp);
        parts.push(seconds);

        let out = "";
        parts.forEach((part) => {
            if (out !== "") {
                out += ":";
            }
            out += formatTimeUnit(part);
        });

        return out;
    }

    function formatTimeUnit(value) {
        if (value < 10) {
            return "0" + value;
        }
        return value;
    }

    function seekAudio(type, duration) {
        let newDuration = duration;
        if (type === "skip") {
            newDuration = playbackPosition + duration;
            newDuration = Math.max(0, newDuration);
            newDuration = Math.min(playbackDuration, newDuration);
        }

        if (podcastAudioElem.current) {
            podcastAudioElem.current.currentTime = newDuration;
        }
        if (backgroundAudioElem.current) {
            if (newDuration < backgroundAudioElem.current.duration) {
                backgroundAudioElem.current.currentTime = newDuration;
            }
        }
    }

    function summonPlayerVolumeModal() {
        setVolumeModalShown(true);
    }

    function updatePlayerVolume() {
        if (podcastAudioElem.current) {
            podcastAudioElem.current.volume = podcastVolume * overallVolume;
        }
        try {
            if (backgroundAudioElem.current) {
                backgroundAudioElem.current.volume = backgroundVolume * overallVolume;
            }
        } catch (e) {
            console.log(e);
        }
    }

    function volumeModalDidCallback(action, data) {
        if (action === PodcastVolumeModalActions.DISMISS) {
            setVolumeModalShown(false);
        } else if (action === PodcastVolumeModalActions.CHANGE) {
            if (data.type === "overall") {
                setOverallVolume(data.progress);
            } else if (data.type === "podcast") {
                setPodcastVolume(data.progress);
            } else if (data.type === "background") {
                setBackgroundVolume(data.progress);
            }
        }
    }

    function fetchPodcastFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        PodcastUtil.getPodcast(podcastId, (action, data) => {
            if (action === "loaded") {
                setPodcast(data);
            } else if (action === "error") {
                console.log(data);
            }
        });
    }

    function logPodcastEventOverNetwork(completed) {
        if (completionSignalNetworkInFlight) return;
        setCompletionSignalNetworkInFlight(true);

        if (completed === undefined) {
            completed = false;
        }

        const data = {
            podcastID : podcastId,
            listenTime : Math.ceil(playbackPosition).toFixed(0),
            completed
        };

        axios.post(ENDPOINTS.podcast.logPodcastEvent, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (completed === true) {
                        setCompletionSignalled(true);
                    }
                } else {
                    console.log(API.formatError(resp));
                }
                setCompletionSignalNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setCompletionSignalNetworkInFlight(false);
            });
    }

    // RENDER

    let mainContent = [];

    if (podcast) {
        let podcastSource = [];
        if (mediaUrl) {
            podcastSource = (
                <source src={mediaUrl} />
            )
        }

        let seekPosition = 0;
        if (playbackDuration > 0) {
            seekPosition = (playbackPosition / playbackDuration) * 100;
        }

        let playPauseIcon = iconPlay;
        let playPauseAccessibilityString = Rosetta.string("podcast.accessibility_play");
        if (playing) {
            playPauseIcon = iconPause;
            playPauseAccessibilityString = Rosetta.string("podcast.accessibility_pause");
        }

        let podcastImage = null;
        if (podcast.hasOwnProperty("bgImageURL")) {
            podcastImage = podcast.bgImageURL;
        }

        /*
            <div
                className={"podcast-player-navigation-icon invert"}
                style={{
                    backgroundImage : ImageUtil.background(iconNavBack)
                }}
                onClick={() => {
                    Navigator.back();
                }}
            />
         */

        let accessibilityPositionString = Rosetta.string("podcast.accessibility_duration", {
            position : TimeUtil.prettyFormatTimeDuration(playbackPosition),
            remaining : TimeUtil.prettyFormatTimeDuration(playbackDuration - playbackPosition)
        });

        const bestExperienceAccessString = Rosetta.string("podcast.best_experience_1") + " " + Rosetta.string("podcast.best_experience_2");

        mainContent = (
            <>
                <div className={"hide-media"}>
                    <audio
                        ref={podcastAudioElem}
                        preload={"auto"}
                        onCanPlay={() => mediaDidUpdate("canplay")}
                        onPlay={() => mediaDidUpdate("play")}
                        onPause={() => mediaDidUpdate("pause")}
                        onEnded={() => mediaDidUpdate("ended")}
                        onWaiting={() => mediaDidUpdate("waiting")}
                        onDurationChange={() => mediaDidUpdate("durationchange")}
                        onLoadedMetadata={() => mediaDidUpdate("canplay")}
                    >
                        {podcastSource}
                    </audio>

                    <audio ref={backgroundAudioElem} preload={"auto"}>
                        <source src={"/audio/podcast_background.mp3"} type={"audio/mp3"}/>
                    </audio>
                </div>

                <div className={"podcast-player-image"} style={{backgroundImage : ImageUtil.background(podcastImage)}}/>

                <div className={"podcast-player-content"}>
                    <div className={"podcast-player-navigation"}>

                        <div className={"spacer"}/>

                        <div
                            className={"podcast-player-navigation-icon invert"}
                            style={{
                                backgroundImage : ImageUtil.background(iconShare),
                                display : "none"
                            }}
                            onClick={() => {
                                // TODO
                            }}
                        />
                    </div>

                    <div className={"podcast-player-header"}>
                        <div className={"podcast-player-header-title"}>{CommonUtil.getOrDefault(podcast, "title")}</div>
                        <div className={"podcast-player-header-subtitle"}>{CommonUtil.getOrDefault(podcast, "categoryTitle")}</div>
                    </div>

                    <div className={"spacer"} />

                    <div className={"podcast-player-controls"}>
                        <div className={"podcast-player-control-playback-buttons"}>
                            <div
                                className={"podcast-player-control-playback-buttons-button invert"}
                                tabIndex={0}
                                aria-label={Rosetta.string("podcast.accessibility_skip_behind")}
                                role={"presentation"}
                                style={{backgroundImage: ImageUtil.background(iconBack)}}
                                onClick={() => {
                                    seekAudio("skip", -15);
                                }}
                            />

                            <div
                                className={"podcast-player-control-playback-buttons-button large"}
                                tabIndex={0}
                                aria-label={playPauseAccessibilityString}
                                role={"presentation"}
                                style={{backgroundImage: ImageUtil.background(playPauseIcon)}}
                                onClick={() => playPauseWasClicked()}
                            />

                            <div
                                className={"podcast-player-control-playback-buttons-button invert"}
                                tabIndex={0}
                                aria-label={Rosetta.string("podcast.accessibility_skip_ahead")}
                                role={"presentation"}
                                style={{backgroundImage: ImageUtil.background(iconForward)}}
                                onClick={() => {
                                    seekAudio("skip", 15);
                                }}
                            />
                        </div>

                        <div className={"podcast-player-control-seekbar"}>
                            <MinimalSeekBar
                                progress={seekPosition}
                                callback={(action, data) => {
                                    if (action === MinimalSeekBarActions.CHANGE) {
                                        let newPosition = parseInt(playbackDuration * (data / 100));

                                        seekAudio("absolute", newPosition);
                                    }
                                }}/>
                        </div>

                        <div className={"podcast-playback-time"} tabIndex={0} aria-label={accessibilityPositionString} role={"presentation"}>
                            <div className={"podcast-player-time-text"}>{formatTime(playbackPosition)}</div>
                            <div className={"spacer"}/>
                            <div className={"podcast-player-time-text"}>{formatTime(playbackDuration - playbackPosition)}</div>
                        </div>

                        <div className={"podcast-player-control-playback-buttons"}>
                            <div
                                className={"podcast-player-control-playback-buttons-button invert"}
                                tabIndex={0}
                                aria-label={Rosetta.string("podcast.accessibility_volume")}
                                role={"presentation"}
                                style={{backgroundImage: ImageUtil.background(iconVolume)}}
                                onClick={() => summonPlayerVolumeModal()}
                            />
                        </div>
                    </div>

                    <div className={"spacer"}/>

                    <div className={"podcast-experience-hint"} tabIndex={0} aria-label={bestExperienceAccessString} role={"presentation"}>
                        <span className={"light"} tabIndex={-1} aria-hidden={true}>{Rosetta.string("podcast.best_experience_1")} {Rosetta.string("podcast.best_experience_2")} </span>
                    </div>

                </div>
            </>
        )
    }

    let mainContentTabIndex = undefined;
    let mainConrentAriaHidden = undefined;
    if (volumeModalShown) {
        mainContentTabIndex = -1;
        mainConrentAriaHidden = true;
    }

    return (
        <>
            <div
                className={"podcast-player-screen"}
                tabIndex={mainContentTabIndex}
                aria-hidden={mainConrentAriaHidden}
            >
                {mainContent}
            </div>

            <PodcastVolumeModal
                shown={volumeModalShown}
                overallVolume={overallVolume}
                podcastVolume={podcastVolume}
                backgroundVolume={backgroundVolume}
                callback={volumeModalDidCallback} />
        </>
    )

}