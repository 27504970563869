import {LoadingSpinner} from "../../loading/LoadingSpinner";

export const MainLoadingScreen = (props) => {

    return (
        <div className={"app-config-blocker"}>
            <LoadingSpinner alignCentre={true} />
        </div>
    )

}