import {Navigator} from "../navigator/Navigator";
import {WebViewScreen} from "../components/screens/common/WebViewScreen";
import {LinkFallbackScreen} from "../components/screens/common/LinkFallbackScreen";

export const CommonUtil = {
    getOrDefault : (obj, keyName, defaultValue, allowNulls) => {
        if (allowNulls === undefined) {
            allowNulls = false;
        }

        if (obj !== undefined && obj !== null && typeof obj === 'object') {
            if (obj.hasOwnProperty(keyName)) {
                if ((!allowNulls && obj[keyName] != null) || allowNulls) {
                    return obj[keyName];
                }
            }
        }
        return defaultValue;
    },
    cloneObject : (object) => {
        return Object.assign({}, object)
    },
    isObject : (object) => {
        return typeof(object) === "object";
    },
    mergeObjects : (o1, o2) => {
        let newFormData = CommonUtil.cloneObject(o1);

        Object.keys(o2).forEach((key) => {
            if (key !== "step") {
                newFormData[key] = o2[key];
            }
        });

        return newFormData;
    },
    getGoogleMapsLink : (addressParts) => {
        let searchQuery = "";
        if (Array.isArray(addressParts)) {
            addressParts.forEach((part) => {
                if (part !== undefined) {
                    if (searchQuery !== "") {
                        searchQuery += "+";
                    }
                    searchQuery += encodeURIComponent(part.toLowerCase().replaceAll(" ", "+"));
                }
            });
        } else {
            searchQuery = encodeURIComponent(addressParts.replaceAll(" ", "+"));
        }

        return "https://www.google.com/maps/search/" + searchQuery + "/";
    },
    implode : (data, delimiter) => {
        if (delimiter === undefined) {
            delimiter = ", ";
        }

        let out = "";

        for (let i = 0; i < data.length; i++) {
            if (data[i] !== null && data[i] !== "") {
                if (out !== "") {
                    out += delimiter;
                }
                out += data[i];
            }
        }

        return out;
    },
    objectToFormData : (object) => {
        let out = new FormData();

        let keys = Object.keys(object);

        keys.forEach((key) => {
            if (object[key] !== null && object[key] !== undefined) {
                out.append(key, object[key]);
            }
        });

        return out;
    },
    toUint8Array : (input) => {
        const out = [];
        (new Uint8Array(input)).forEach((v) => {
            out.push(v);
        });
        return out;
    },
    /** Mobile Safari SUUUUUCKS **/
    openWindow : (url, target) => {
        setTimeout(() => {
            let openRes = window.open(url, target);
            if (!openRes) {
                Navigator.navigate(
                    <LinkFallbackScreen url={url} />
                );
            }
        });
    },
    interceptLink : (e) => {
        console.log("Intercepting link...", e);
        let useTarget = CommonUtil.findParent(e.target, { targetTag : "a" })
        console.log("TARGET: ", useTarget);
        if (useTarget && useTarget.target) {
            console.log("Element has a target property...");
            if (useTarget.target.toLowerCase() === "_blank") {
                console.log("Element is going to launch a window. Intercept.");
                e.stopPropagation();
                e.preventDefault();
                CommonUtil.openWindow(useTarget.href);
            }
        }
    },
    /**
     * Finds a Parent Element based on Tag Name or Class Name
     *
     * Target Data Format: { targetTag : "", targetClass : "" }
     *
     * @param elem          Element to find parent of, parent may be itself
     * @param targetData    The target data, see format for more info
     * @param isRoot        Used during recursion, do not set manually
     * @returns {*}         The found element
     */
    findParent : (elem, targetData, isRoot) => {
        let out = null;
        if (isRoot === undefined) {
            // If this is the root call,
            out = elem;
        }

        if (elem && targetData) {
            let foundCandidate = false;
            if (targetData.targetTag) {
                if (targetData.targetTag.toLowerCase() === elem.tagName.toLowerCase()) {
                    out = elem;
                    foundCandidate = true;
                }
            } else if (targetData.targetClass) {
                if (elem.classList.includes(targetData.targetClass)) {
                    out = elem;
                    foundCandidate = true;
                }
            }

            if (!foundCandidate) {
                if (elem.parentElement) {
                    let recurseResult = CommonUtil.findParent(elem.parentElement, targetData, false);
                    if (recurseResult) {
                        out = recurseResult;
                    }
                }
            }
        }

        return out;
    },

    linkifyString : (inputText) => {
        let replacedText, replacePattern1, replacePattern2, replacePattern3;

        //URLs starting with http://, https://, or ftp://
        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 = /(^|[^\/])(www\.[\w.?#\/=\-_&]+[-A-Z-a-z0-9+&@#\/%=~_|])/gim ///(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

        //Change email addresses to mailto:: links.
        replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

        return replacedText;
    },

    stripHTML : (input) => {
        const doc = new DOMParser().parseFromString(input, "text/html");
        const walker = document.createTreeWalker(
            doc.body,
            NodeFilter.SHOW_TEXT,
            null,
            false
        );
        const strings = [];
        let currentNode = null;
        while (currentNode = walker.nextNode()) {
            strings.push(currentNode.nodeValue);
        }

        return strings.join(" ");
    }
}