import {API, ENDPOINTS} from "../network/API";
import axios from "axios";
import {DataManager} from "./DataManager";

export const PodcastUtil = {
    // Cache will hold any Podcasts previously loaded.
    // We do this to speed up loading podcast metadata between screens.
    _podcastCache : [],
    getPodcast : (id, callback) => {
        // Check to see if Podcast is in cache
        let cachedPodcast = PodcastUtil.getPodcastFromCache(id);
        if (cachedPodcast) {
            if (callback) {
                callback("loaded", cachedPodcast);
            }
            return;
        }

        let url = ENDPOINTS.podcast.getPodcast + "?podcastID=" + id;

        axios.get(url)
            .then((r) => {
                let error = null;

                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.hasOwnProperty("podcast")) {
                        let podcast = resp.data.podcast;
                        // Put podcast into cache now
                        PodcastUtil._podcastCache.push(podcast);

                        if (callback) {
                            callback("loaded", podcast);
                        }
                    } else {
                        error = "Invalid response";
                    }
                } else {
                    error = API.formatError(resp);
                }

                if (error) {
                    if (callback) {
                        callback("error", error);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
                if (callback) {
                    callback("error", e.getMessage());
                }
            });
    },
    getPodcastFromCache : (id) => {
        id = parseInt(id);
        for (let i = 0; i < PodcastUtil._podcastCache.length; i++) {
            if (parseInt(PodcastUtil._podcastCache[i].id) === id) {
                return PodcastUtil._podcastCache[i];
            }
        }
        return null;
    },
    feedbackKeys : {
        RATING : "rating",
        RECOMMEND : "recommend",
        PACE : "pace",
        VOICE : "voice",
        CONTENT : "content"
    },
    createFeedbackOption : (label, value) => {
        return {
            label,
            value
        };
    },
    hasCompletedFeedback : (podcastId) => {
        const podcastFeedbackIds = DataManager.getPodcastFeedbackIds();
        console.log("Feedback IDs: ", podcastFeedbackIds);
        return podcastFeedbackIds.includes(podcastId);
    },
    setCompletedFeedback : (podcastId) => {
        const podcastFeedbackIds = DataManager.getPodcastFeedbackIds();
        if (!podcastFeedbackIds.includes(podcastId)) {
            podcastFeedbackIds.push(podcastId);
            console.log("Set feedback IDs: ", podcastFeedbackIds);
            DataManager.setPodcastFeedbackIds(podcastFeedbackIds);
        }
    }
}