import React, {useEffect, useRef, useState} from 'react';

import './AlertModal.css';
import Rosetta from "../../rosetta/Rosetta";
import {BaseModal} from "./BaseModal";
import {ImageUtil} from "../../util/ImageUtil";
import WindowUtil from "../../util/WindowUtil";

export const AlertModal = {
    showModal : (title, message, buttons, icon) => {
        window._showModal(title, message, buttons, icon);
    },
    showError : (message) => {
        AlertModal.showModal(
            Rosetta.string("common.error"),
            message
        );
    },
    dismissModal : () => {
        window._dismissModal();
    },
    button : (label, callback, className) => {
        return {
            label,
            click : callback,
            className
        };
    }
}

export const AlertModalComponent = (props) => {

    const {shown} = props;

    const {title} = props;
    const {message} = props;
    const {icon} = props;
    const {buttons} = props;
    const {callback} = props;

    const [forceDismiss, setForceDismiss] = useState(false);

    const titleElement = useRef();

    useEffect(() => {
        window._dismissModal = () => {
            setForceDismiss(true);
        }
    }, []);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();

            if (titleElement.current) {
                titleElement.current.focus();
            }
        } else {
            WindowUtil.unlockBodyScroll();
            setForceDismiss(false);
        }
    }, [shown]);

    function handleCallback(action) {
        if (callback) {
            callback(action);
        }
    }

    // RENDER

    if (!shown) {
        return [];
    }

    let iconElem = [];
    if (icon !== undefined) {
        iconElem = (
            <div
                className={"alert-modal-dialog-icon"}
                style={{backgroundImage : ImageUtil.background(icon)}}
            />
        );
    }

    let titleElem = [];
    if (title !== undefined) {
        titleElem = (
            <div
                className={"alert-modal-dialog-title"}
                role={"heading"}
                aria-level={1}
                autoFocus={true}
                tabIndex={0}
                ref={titleElement}
            >
                {title}
            </div>
        );
    }

    let messageElem = "";
    if (message !== undefined) {
        messageElem = message;
    }

    let buttonsElem = [
        {
            label : Rosetta.string("common.ok"),
            click : () => {
                window._dismissModal();
            }
        }
    ];
    if (buttons !== undefined) {
        buttonsElem = buttons;
    }

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            {iconElem}

            {titleElem}

            <div
                className={"alert-modal-dialog-message"}
                tabIndex={0}
            >
                {messageElem}
            </div>

            <div className={"alert-modal-dialog-buttons"}>
                {buttonsElem.map((button, index) => {
                    let className = "alert-dialog-button";
                    if (button.hasOwnProperty("className")) {
                        className += " " + button.className;
                    }

                    return (
                        <button
                            className={className}
                            onClick={button.click}
                            tabIndex={0}
                        >
                            {button.label}
                        </button>
                    );
                })}
            </div>

        </BaseModal>
    );

}
