import axios from 'axios';
import Rosetta from "../rosetta/Rosetta";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common["UW-Platform"] = process.env.REACT_APP_REQUEST_UW_PLATFORM;
axios.defaults.headers.common["BundleID"] = process.env.REACT_APP_REQUEST_BUNDLE_ID;
axios.defaults.headers.common["UW-OSVersion"] = process.env.REACT_APP_REQUEST_UW_OSVERSION;
axios.defaults.headers.common["UW-AppBuild"] = process.env.REACT_APP_REQUEST_UW_APPBUILD;
axios.defaults.headers.common["UW-AppVersion"] = process.env.REACT_APP_REQUEST_UW_APPVERSION;
axios.defaults.headers.common["UW-DeviceModel"] = process.env.REACT_APP_REQUEST_UW_DEVICEMODEL;
axios.defaults.headers.common["UW-Lang"] = process.env.REACT_APP_REQUEST_UW_LANG;
axios.defaults.headers.common["UW-GenAILevel"] = 2; //process.env.REACT_APP_REQUEST_UW_GenAILevel;

export const API = (function(axios) {
    return {
        _authFailureCallbacks : [],
        init : () => {
            axios.interceptors.response.use(
                (response) => {
                    API._processInterceptedResponse(response);
                    return response;
                },
                (error) => {
                    API._processInterceptedResponse(error.response);
                    return error;
                }
            );

            axios.defaults.headers.common["UW-Lang"] = navigator.language.toLowerCase();
        },
        _processInterceptedResponse : (response) => {
            // Sometimes the response may be wrapped in an AxiosError object
            // attempt to free that response now.
            let useResponse = response;
            if (useResponse.hasOwnProperty("response")) {
                useResponse = useResponse.response;
            }

            if (useResponse.status === 401) {
                console.log("Intercepted 401 message, checking...");
                const resp = API.parse(useResponse);
                if (!resp.success) {
                    console.log("Intercept: 401 response is valid format.");
                    if (resp.error) {
                        if (parseInt(resp.error.code) === 503) {
                            console.log("Intercept: 401 response has 503 error code. Send callbacks...");
                            for (let i = API._authFailureCallbacks.length - 1; i >= 0; i--) {
                                try {
                                    API._authFailureCallbacks[i](useResponse.status, resp, useResponse);
                                } catch (e) {
                                    API._authFailureCallbacks.splice(i, 1);
                                }
                            }
                        }
                    }
                }
            }
        },
        getAPIUrl : (path) => {
            if (!(path.startsWith("http://") && path.startsWith("https://"))) {
                let out = process.env.REACT_APP_BASE_URL;
                if (path) {
                    out += path;
                }
                return out;
            }
        },
        getAppDownloadUrl : (bundleID) => {
            if (!bundleID) {
                if (process.env.hasOwnProperty("REACT_APP_REQUEST_BUNDLE_ID")) {
                    bundleID = process.env.REACT_APP_REQUEST_BUNDLE_ID;
                } else {
                    bundleID = "";
                }
            }
            return API.getAPIUrl("v2/api/info/downloadApp/" + bundleID);
        },
        getTermsUrl : () => {
            return API.getAPIUrl("v2/api/info/terms");
        },
        getPrivacyPolicyUrl : () => {
            return API.getAPIUrl("v2/api/info/privacyPolicy");
        },
        setAuthenticationToken : (token) => {
            if (token !== undefined && token !== null) {
                axios.defaults.headers.common["AuthToken"] = token;
            } else if (token === null) {
                delete axios.defaults.headers.common["AuthToken"];
            }
        },
        setBundleId : (bundleId) => {
            let useBundleId = process.env.REACT_APP_REQUEST_BUNDLE_ID;
            if (bundleId) {
                useBundleId = bundleId;
            }
            axios.defaults.headers.common["BundleID"] = useBundleId;
        },
        setAuthFailureCallback : (callback) => {
            API._authFailureCallbacks.push(callback);

            // if (callback instanceof Function) {
            //     axios.defaults.validateStatus = (status) => {
            //         if (status === 401 || status === 403) {
            //             callback(status);
            //         } else {
            //             return status;
            //         }
            //     }
            // }
        },
        parse : (resp) => {
            let out = {
                success : false,
                data : null,
                error : {
                    desc : Rosetta.string("common.error_common_uncoded"),
                    code : -1
                }
            };

            if (resp !== null) {
                out.success = resp.data.success;
                if (out.success) {
                    if (resp.data.hasOwnProperty("data")) {
                        out.data = resp.data.data;
                    } else {
                        out.data = resp.data;
                    }
                } else {
                    if (resp.data.error !== undefined) {
                        out.error.desc = resp.data.error.desc;
                        out.error.code = resp.data.error.code;
                    }
                }
            }

            return out;
        },
        formatError : (resp) => {
            if (!resp.success) {
                return resp.error.desc + " [" + resp.error.code + "]";
            }
            return "";
        },
        defaultError : (errorCode) => {
            return Rosetta.string("common.error_common_unknown", {error_code : errorCode});
        },
        objectToPostData : (object) => {
            let keys = Object.keys(object);
            let formData = new FormData();

            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let value = object[key];
                if (typeof value === 'object' && value !== null) {
                    value = JSON.stringify(value);
                }

                formData.append(key, value);
            }

            return formData;
        },
        getHttpHeaders : (contentType, accept) => {
            if (!contentType) {
                contentType = "application/json";
            }

            if (!accept) {
                accept = "application/json";
            }

            const out = {
                "Accept" : accept,
                "Content-Type" : contentType,
                "UW-Platform" : process.env.REACT_APP_REQUEST_UW_PLATFORM,
                "BundleID" : process.env.REACT_APP_REQUEST_BUNDLE_ID,
                "UW-OSVersion" : process.env.REACT_APP_REQUEST_UW_OSVERSION,
                "UW-AppBuild" : process.env.REACT_APP_REQUEST_UW_APPBUILD,
                "UW-AppVersion" : process.env.REACT_APP_REQUEST_UW_APPVERSION,
                "UW-DeviceModel" : process.env.REACT_APP_REQUEST_UW_DEVICEMODEL,
                "UW-Lang" : process.env.REACT_APP_REQUEST_UW_LANG,
                "UW-GenAILevel" : 2
            };

            const authToken = axios.defaults.headers.common["AuthToken"];
            if (authToken) {
                out["AuthToken"] = authToken;
            }

            return out;
        }
    }
}) (axios);

export const ENDPOINTS = {
    auth : {
        refreshAuthToken : "v2/api/auth/refreshAuthToken",
        getOAuthLoginUrl : "v2/api/auth/getOAuthLoginUrl",
        ssoLogin : "v2/api/auth/ssoLogin"
    },
    chat : {
        getChatFlowForTrigger : "v2/api/chat/getChatFlowForTrigger",
        getNextMessage : "v2/api/chat/getNextMessage"
    },
    organisation : {
        getCampuses : "v2/api/organisation/getCampuses",
        getDepartments : "v2/api/organisation/getDepartments"
    },
    user : {
        getRoleTypes : "v2/api/user/getRoleTypes",
        quickRegister : "v2/api/user/quickRegister",
        updateUserRoleCampus : "v2/api/user/updateUserRoleCampus",
        updateUserTimeZone : "v2/api/user/updateUserTimeZone"
    },
    service : {
        getService : "v2/api/service/getService",
        submitResponse : "v2/api/service/submitResponse",
        getAppServices : "v2/api/service/getAppServices",
        getAppService : "v2/api/service/getAppService"
    },
    podcast : {
        getPodcast : "v2/api/podcast/getPodcast",
        logPodcastEvent : "v2/api/podcast/logPodcastEvent",
        verifyPodcastCompleted : "v2/api/podcast/verifyPodcastCompleted",
        submitFeedback : "v2/api/podcast/submitFeedback",
    },
    habit : {
        getPomodoroSuggestions : "v2/api/habit/getPomodoroSuggestions"
    },
    plugin : {
        getConfig : "v2/api/plugin/getConfig/",
        debugGenerate401_get : "v2/api/plugin/debugGenerate401"
    },
    emotion : {
        createEmotionLog : "v2/api/emotion/createEmotionLog"
    }
}