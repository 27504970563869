import {DataManager} from "../../../util/DataManager";

// Shh, be wery, wery qwiet
export const DebugLandingScreen = (props) => {

    function removeLocationFlag() {
        DataManager.setRoleConfigured(false);
        console.log("REMOVED LOCATION FLAG");
    }

    function clearLocationValue() {
        DataManager.setRoleCampusData(null);
        console.log("CLEARED LOCATION DATA");
    }

    function expireLocationValue() {
        const campusData = DataManager.getRoleCampusData();
        if (campusData) {
            campusData.expires = 0;
            DataManager.setRoleCampusData(campusData);
        }
        console.log("EXPIRED LOCATION VALUE");
    }

    function resetLocalStorage() {
        DataManager.clear(true, true);
        console.log("CALLED FOR CLEAR");
    }

    return (
        <div>
            <h1>Its a secret to everybody</h1>

            <div>
                <button onClick={clearLocationValue}>
                    Clear Location Value
                </button>
            </div>

            <div>
                <button onClick={removeLocationFlag}>
                    Remove Location Flag
                </button>
            </div>

            <div>
                <button onClick={expireLocationValue}>
                    Expire Location Data
                </button>
            </div>

            <div>
                <button onClick={resetLocalStorage}>
                    Reset Local Storage
                </button>
            </div>
        </div>
    )

}