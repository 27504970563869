import "./SSOLoginToast.css";
import {ImageUtil} from "../../util/ImageUtil";
import { v4 as uuidv4 } from "uuid";

import iconLock from "../../assets/ic_lock.svg";
import {useEffect, useRef, useState} from "react";
import {DataManager} from "../../util/DataManager";
import Rosetta from "../../rosetta/Rosetta";
import {AppConfig} from "../../util/AppConfig";

export const SSOLoginToastActions = {
    DISMISS : "ssoLoginToastActions.dismiss"
};

export const SSOLoginToast = (props) => {

    const {shown} = props;
    const {callback} = props;

    const [dismissing, setDismissing] = useState(false);
    const [key, setKey] = useState(uuidv4());
    const [user, setUser] = useState(null);

    const contentTimeout = useRef();
    const dismissTimeout = useRef();

    useEffect(() => {
        if (shown) {
            setUser(DataManager.getUser());

            setKey(uuidv4());

            contentTimeout.current = setTimeout(() => {
                setDismissing(true);
            }, 6000);
        } else {
            setDismissing(false);
        }
    }, [shown]);

    useEffect(() => {
        if (dismissing) {
            setKey(uuidv4());

            clearTimeout(contentTimeout.current);
            dismissTimeout.current = setTimeout(() => {
                if (callback) {
                    callback(SSOLoginToastActions.DISMISS);
                }
            }, 599);
        }
    }, [dismissing]);

    let extraClass = "";
    if (dismissing) {
        extraClass = " dismissing";
    }

    if (!shown) return [];

    let themeName = AppConfig.getConfig(AppConfig.keys.HEADER_THEME, "light");
    let headerColour = AppConfig.getConfig(AppConfig.keys.HEADER_BACKGROUND_COLOUR, null);

    let userName = "";
    if (user) {
        userName = Rosetta.string("auth.sso_toast_name_format", {
            givenName : user.firstName,
            familyName : user.lastName
        });
    }

    let toastStyle = {};
    if (headerColour) {
        toastStyle.backgroundColor = headerColour;
    }

    return (
        <div
            className={"sso-login-toast " + themeName + extraClass}
            key={key}
            style={toastStyle}
            onClick={() => setDismissing(true)}
        >
            <div className={"sso-login-icon"}>
                <div
                    className={"sso-login-icon-graphic"}
                    style={{backgroundImage : ImageUtil.background(iconLock)}}
                />
            </div>

            <div className={"sso-login-content"}>
                <div className={"sso-login-title"}>{Rosetta.string("auth.sso_toast_title")}</div>
                <div className={"sso-login-message"}>{userName}</div>
            </div>
        </div>
    );

}