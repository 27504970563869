import axios from "axios";
import {API, ENDPOINTS} from "../network/API";
import {AlertModal} from "../components/modal/AlertModal";
import Rosetta from "../rosetta/Rosetta";
import {DataManager} from "./DataManager";

export const AuthUtil = {
    ssoActions : {
        PROGRESSING : "ssoAuth.progressing",
        ERROR : "ssoAuth.error",
        WAITING : "ssoAuth.waiting",
        DONE : "ssoAuth.done",
        DISMISS : "ssoAuth.dismiss"
    },
    _windowInstance : undefined,
    _windowWatcher : undefined,
    _ssoMessageListener : undefined,
    _callbacks : [],
    init : () => {
        AuthUtil._ssoMessageListener = (e) => {
            if (e.data) {
                try {
                    // Only be concerned with data which starts with "uwbsso."
                    if (e.data.startsWith && e.data.startsWith("uwbsso.")) {
                        let inData = e.data;
                        inData = inData.substring(7);

                        // Perform login
                        AuthUtil.performSSOLogin(inData);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        }
        window.onmessage = AuthUtil._ssoMessageListener;

        AuthUtil._windowWatcher = setInterval(() => {
            if (AuthUtil._windowInstance) {
                if (AuthUtil._windowInstance.closed) {
                    AuthUtil._triggerCallbacks(AuthUtil.ssoActions.DISMISS);
                    AuthUtil._windowInstance = undefined;
                }
            }
        }, 1000);
    },
    startSSOLogin : () => {
        const formData = new FormData();

        let state = null;
        if (window._uwbSaveState) {
            state = window._uwbSaveState();
            if (state) {
                formData.append("state", state);
            }
        }

        axios.post(ENDPOINTS.auth.getOAuthLoginUrl, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    AuthUtil._windowInstance = window.open(resp.data.url, "uwbSSOLogin", "scrollbars=yes,menubar=no,width=420,height=640,resizeable=no,toolbar=no,location=no,status=no");
                    if (!AuthUtil._windowInstance) {
                        // iOS fallback - Just load the URL and hope we restore
                        // properly on the other end.
                        window.location.href = resp.data.url;
                    }

                    AuthUtil._triggerCallbacks(AuthUtil.ssoActions.PROGRESSING);
                } else {
                    AlertModal.showError(API.formatError(resp));
                    AuthUtil._triggerCallbacks(AuthUtil.ssoActions.ERROR, API.formatError(resp));
                }
            })
            .catch((e) => {
                AuthUtil._triggerCallbacks(AuthUtil.ssoActions.ERROR, e.message);
            });
    },
    performSSOLogin : (token, callback) => {
        const formData = new FormData();
        formData.append("token", token);
        formData.append("refreshToken", DataManager.getRefreshToken());

        // Make sure the current SSO auth state is correct
        // This may not be right when incoming from a redirect
        AuthUtil._triggerCallbacks(AuthUtil.ssoActions.PROGRESSING);

        axios.post(ENDPOINTS.auth.ssoLogin, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data) {
                        if (resp.data.auth) {
                            DataManager.setAuthToken(resp.data.auth.token);
                            DataManager.setRefreshToken(resp.data.auth.refreshToken);
                        }

                        console.log("SSO Login OK!");
                        AuthUtil._triggerCallbacks(AuthUtil.ssoActions.DONE, {
                            user : resp.data.user
                        });
                    }
                } else {
                    console.log(API.formatError(resp));
                    AlertModal.showError(API.formatError(resp));
                    AuthUtil._triggerCallbacks(AuthUtil.ssoActions.ERROR, API.formatError(resp));
                }
            })
            .catch((e) => {
                AlertModal.showError(Rosetta.string("auth.sso_failure_generic"));
                console.log(e);
                AuthUtil._triggerCallbacks(AuthUtil.ssoActions.ERROR, Rosetta.string("auth.sso_failure_generic"));
            });
    },
    addCallback : (callback) => {
        AuthUtil._callbacks.push(callback);
    },
    removeCallback : (callback) => {
        for (let i = 0; i < AuthUtil._callbacks.length; i++) {
            if (AuthUtil._callbacks[i] === callback) {
                delete AuthUtil._callbacks[i];
                break;
            }
        }
    },
    debugTriggerToast : () => {
        AuthUtil._triggerCallbacks(AuthUtil.ssoActions.DONE, {});
    },
    _triggerCallbacks : (action, data) => {
        AuthUtil._callbacks.forEach((callback) => {
            try {
                callback(action, data);
            } catch (e) {
                console.log(e);
            }
        });
    }
}