import sosIcon from "../../../assets/ic_sos.svg";
import {AppConfig} from "../../../util/AppConfig";
import {SupportPageUtil} from "../../../util/SupportPageUtil";
import charleyIcon from "../../../assets/ic_charley_filled.svg";
import {API} from "../../../network/API";
import {ChatHeaderQRCode} from "./ChatHeaderQRCode";
import Rosetta from "../../../rosetta/Rosetta";
import {ImageUtil} from "../../../util/ImageUtil";
import overflowIcon from "../../../assets/ic_options.svg";
import {DataManager} from "../../../util/DataManager";
import {Navigator} from "../../../navigator/Navigator";
import {RoleLocationSelectionScreen} from "../user/RoleLocationSelectionScreen";
import {SupportListScreen} from "../support/SupportListScreen";
import {ChatListScreen} from "./ChatListScreen";
import {useEffect, useRef, useState} from "react";

import "./ChatNavigation.css";
import {CommonUtil} from "../../../util/CommonUtil";
import {UserLocationSelectionScreen} from "../user/UserLocationSelectionScreen";

export const ChatNavigation = (props) => {

    const [showNavigation, setShowNavigation] = useState(true);
    const [showSos, setShowSos] = useState(true);
    const [showCharley, setShowCharley] = useState(true);
    const [showQRLink, setShowQRLink] = useState(true);
    const [showOverflow, setShowOverflow] = useState(true);

    const appConfigCallback = useRef();

    useEffect(() => {
        updateVisibility();

        appConfigCallback.current = (action, data) => {
            if (action === AppConfig.actions.LOADED) {
                updateVisibility();
            }
        };
        AppConfig.addCallback(appConfigCallback.current);

        return () => {
            AppConfig.removeCallback(appConfigCallback.current);
        }
    }, []);

    function updateVisibility() {
        setShowNavigation(AppConfig.getConfig(AppConfig.keys.CHAT_SHOW_NAVIGATION, true));
        setShowSos(AppConfig.getConfig(AppConfig.keys.CHAT_SHOW_SOS, true));
        setShowCharley(AppConfig.getConfig(AppConfig.keys.CHAT_SHOW_CHARLEY, true));
        setShowQRLink(AppConfig.getConfig(AppConfig.keys.CHAT_SHOW_QR_LINK, true));
        setShowOverflow(AppConfig.getConfig(AppConfig.keys.CHAT_SHOW_OVERFLOW, true));
    }

    function moveToChatList() {
        Navigator.navigate(<ChatListScreen />);
    }

    function moveToSupportPage(sectionTypeID, parentID) {
        if (!SupportPageUtil.getCachedLocationID()) {
            SupportPageUtil._temporarySupportData = {
                sectionTypeID,
                parentID
            };

            Navigator.navigate(<UserLocationSelectionScreen />);

            return;
        }

        Navigator.navigate(
            <SupportListScreen
                supportTypeId={sectionTypeID}
                parentId={parentID} />
        );
    }

    if (!showNavigation) return [];

    let actionSos = [];
    if (showSos) {
        actionSos = (
            <div
                className={"navigation-action"}
                style={{backgroundImage: "url(" + sosIcon + ")"}}
                tabIndex={0}
                aria-label={Rosetta.string("chat.accessibility_sos_button")}
                onClick={() => {
                    // 602 (Page), 95 (Podcast), 4 (Here and Now),
                    // 35 (Belly Breathing), 3 (Breathing landing)
                    // 2 (Pomodoro), 17 (Website), 153 (Video),
                    // 216 (Page),
                    const sosServiceID = AppConfig.getConfig(AppConfig.keys.SOS_SERVICE_ID, 74); // TODO Debugging default
                    if (sosServiceID !== undefined) {
                        //moveToService(sosServiceID);
                        moveToSupportPage(SupportPageUtil.types.ASSISTANT_NEED_HELP, sosServiceID);
                    }
                }}
            />
        );
    }

    let actionCharley = [];
    if (showCharley) {
        actionCharley = (
            <div
                className={"navigation-action"}
                tabIndex={0}
                aria-label={Rosetta.string("chat.accessibility_charley_button")}
                style={{
                    backgroundImage: "url(" + charleyIcon + ")",
                    marginLeft: "10px"
                }}
                onClick={() => {
                    moveToChatList();
                }}
            />
        )
    }

    let qrLink = [];
    if (showQRLink) {
        qrLink = (
            <div className={"download-prompt-container"}>
                <span
                    className={"qr-display"}
                    tabIndex={0}
                    aria-label={Rosetta.string("chat.accessibility_qr_code")}
                    onClick={() => {
                        CommonUtil.openWindow(API.getAppDownloadUrl())
                    }}>
                    <ChatHeaderQRCode url={API.getAppDownloadUrl()}/>
                </span>

                <a
                    className={"download-prompt"}
                    href={API.getAppDownloadUrl()}
                    tabIndex={0}
                    target={"_blank"}
                    rel={"noreferrer"}
                    onClick={CommonUtil.interceptLink}
                >
                    {Rosetta.string("chat.download_title_qr")} <span
                    className={"underlined"}>{Rosetta.string("chat.download_title_click")}</span>
                </a>
            </div>
        );
    }

    let actionOverflow = [];
    if (showOverflow) {
        actionOverflow = (
            <div
                className={"overflow"}
                style={{backgroundImage: ImageUtil.background(overflowIcon)}}
                tabIndex={0}
                aria-label={Rosetta.string("chat.accessibility_overflow_button")}
                onClick={() => {
                    //moveToChatList();
                    const supportPageID = AppConfig.getConfig(AppConfig.keys.SUPPORT_SECTION_ID, undefined);
                    console.log("OVERFLOW ID: " + supportPageID);
                    if (supportPageID) {
                        moveToSupportPage(SupportPageUtil.types.ASSISTANT_SUPPORT, supportPageID);
                    }
                }
                }/>
        );
    }

    return (
        <div className={"chat-navigation"}>
            {actionSos}

            {actionCharley}

            <div className={"spacer"}/>

            {qrLink}

            {actionOverflow}
        </div>
    );

}