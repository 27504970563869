import {useEffect, useRef, useState} from "react";
import {ImageUtil} from "../../../util/ImageUtil";

import "./PodcastFeedbackScreen.css";

import iconStarFilled from "../../../assets/icon_rating_star_filled.svg";
import iconStarEmpty from "../../../assets/icon_rating_star_empty.svg";
import {PodcastUtil} from "../../../util/PodcastUtil";
import {CommonUtil} from "../../../util/CommonUtil";
import axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../modal/AlertModal";
import Rosetta from "../../../rosetta/Rosetta";
import {Navigator} from "../../../navigator/Navigator";
import {UIBlocker} from "../../ui/UIBlocker";

export const PodcastFeedbackScreen = (props) => {

    const FORM_RATING = "rating";
    const FORM_RECOMMEND = "recommend";
    const FORM_IMPROVE = "improve";

    const {podcast} = props;
    const {listenTime} = props;

    const [answers, setAnswers] = useState({});
    const [backgroundImage, setBackgroundImage] = useState(null);

    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    useEffect(() => {
        if (listenTime) {
            logPodcastEventOverNetwork();
        }
    }, []);

    useEffect(() => {
        if (podcast) {
            if (podcast.hasOwnProperty("bgImageURL")) {
                setBackgroundImage(podcast.bgImageURL);
            }
        }
    }, [podcast]);

    function putAnswer(keyName, value) {
        const newAnswers = CommonUtil.cloneObject(answers);
        if (value !== undefined) {
            newAnswers[keyName] = value;
        } else if (newAnswers.hasOwnProperty(keyName)) {
            delete newAnswers[keyName];
        }
        setAnswers(newAnswers);
    }

    function getAnswer(keyName, defaultValue) {
        if (answers.hasOwnProperty(keyName)) {
            return answers[keyName];
        }
        return defaultValue;
    }

    function ratingDidCallback(action, data) {
        if (action === "change") {
            const currentRating = getAnswer(FORM_RATING, 0);
            if (currentRating === data && data === 1) {
                // Allow user to click the 1 star when it is filled to
                // give a 0 rating again
                data = 0;
            }

            putAnswer(FORM_RATING, data);
        }
    }

    function recommendDidCallback(action, data) {
        if (action === "change") {
            putAnswer(FORM_RECOMMEND, data);
        }
    }

    function improveDidCallback(action, data) {
        if (action === "change") {
            let currentAnswers = getAnswer("improve", undefined);
            if (currentAnswers === undefined) {
                currentAnswers = [];
            }

            let hasItem = false;
            for (let i = 0; i < currentAnswers.length; i++) {
                if (currentAnswers[i] === data) {
                    currentAnswers.splice(i, 1);
                    hasItem = true;
                    break;
                }
            }

            if (!hasItem) {
                currentAnswers.push(data);
            }

            putAnswer(FORM_IMPROVE, currentAnswers);
        }
    }

    function validateForm() {
        const rating = getAnswer(FORM_RATING, null);
        if (rating === null || rating < 1) {
            AlertModal.showError(Rosetta.string("podcast.feedback_form_validate_rating"));
            return false;
        }

        const recommend = getAnswer(FORM_RECOMMEND, null);
        if (recommend === null) {
            AlertModal.showError(Rosetta.string("podcast.feedback_form_validate_recommend"));
            return false;
        }

        return true;
    }

    function submitFeedbackOverNetwork() {
        if (submitNetworkInFlight) return;

        if (!validateForm()) {
            return;
        }

        setSubmitNetworkInFlight(true);

        const postData = {
            podcastID : podcast ? podcast.id : undefined,
            rating : getAnswer(FORM_RATING),
            recommend : getAnswer(FORM_RECOMMEND),
            pace : 0,
            voice : 0,
            content : 0,
            comment : ""
        };

        getAnswer(FORM_IMPROVE, []).forEach((value) => {
            switch (value) {
                case PodcastUtil.feedbackKeys.PACE:
                    postData.pace = 1; break;
                case PodcastUtil.feedbackKeys.VOICE:
                    postData.voice = 1; break;
                case PodcastUtil.feedbackKeys.CONTENT:
                    postData.content = 1; break;
                default:
                    console.log("Unknown property:", value);
            }
        })

       axios.post(ENDPOINTS.podcast.submitFeedback, postData)
           .then((r) => {
               const resp = API.parse(r);
               if (resp.success) {
                   if (podcast) {
                       PodcastUtil.setCompletedFeedback(podcast.id);
                       console.log("Marked ID: " + podcast.id + " as completed.");
                   }

                   AlertModal.showModal(
                       Rosetta.string("podcast.feedback_submission_success_title"),
                       Rosetta.string("podcast.feedback_submission_success_message"),
                       [
                           AlertModal.button(
                               Rosetta.string("podcast.feedback_submission_success_action"),
                               () => {
                                   AlertModal.dismissModal();
                                   Navigator.back();
                               }
                           )
                       ]
                   );
               } else {
                   AlertModal.showError(API.formatError(resp));
               }
               setSubmitNetworkInFlight(false);
           })
           .catch((e) => {
               console.log(e);
               AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "PF1000"}));
               setSubmitNetworkInFlight(false);
           });
    }

    function logPodcastEventOverNetwork() {
        const data = {
            podcastID : podcast.id,
            listenTime : listenTime,
            completed : true
        };

        axios.post(ENDPOINTS.podcast.logPodcastEvent, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    console.log("Progress updated...");
                } else {
                    console.log(API.formatError(resp));
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // RENDER

    const recommendOptions = [
        PodcastUtil.createFeedbackOption(Rosetta.string("common.yes"), 1),
        PodcastUtil.createFeedbackOption(Rosetta.string("common.no"), 0)
    ];

    const improveOptions = [
        PodcastUtil.createFeedbackOption(Rosetta.string("podcast.feedback_form_improve_pace"), PodcastUtil.feedbackKeys.PACE),
        PodcastUtil.createFeedbackOption(Rosetta.string("podcast.feedback_form_improve_voice"), PodcastUtil.feedbackKeys.VOICE),
        PodcastUtil.createFeedbackOption(Rosetta.string("podcast.feedback_form_improve_content"), PodcastUtil.feedbackKeys.CONTENT)
    ];

    let uiBlocker = [];
    if (submitNetworkInFlight) {
        uiBlocker = (
            <div className={"blocker-container"}>
                <UIBlocker blocked={true} />
            </div>
        );
    }

    const bodyStyle = {
        background : "linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))"
    };
    if (backgroundImage) {
        bodyStyle.background = bodyStyle.background + "," + ImageUtil.background(backgroundImage);
    }

    return (
        <div className={"podcast-feedback-screen"} style={bodyStyle}>

            <div className={"podcast-feedback-form-content-container"}>
                <FeedbackTitle
                    title={podcast ? podcast.title : "" }
                    subtitle={podcast ? podcast.categoryTitle : "" }
                />
                <FeedbackSpacer />

                <FeedbackRating
                    label={Rosetta.string("podcast.feedback_form_rating_label")}
                    value={getAnswer(FORM_RATING, 0)}
                    callback={ratingDidCallback}
                />
                <FeedbackSpacer />

                <FeedbackRadio
                    label={Rosetta.string("podcast.feedback_form_recommend_label")}
                    options={recommendOptions}
                    columnCount={2}
                    value={getAnswer(FORM_RECOMMEND)}
                    callback={recommendDidCallback}
                />
                <FeedbackSpacer />

                <FeedbackRadio
                    label={Rosetta.string("podcast.feedback_form_improve_label")}
                    options={improveOptions}
                    columnCount={3}
                    value={getAnswer(FORM_IMPROVE, [])}
                    callback={improveDidCallback}
                />
                <FeedbackSpacer />

                <FeedbackButton
                    label={Rosetta.string("podcast.feedback_form_submit")}
                    callback={submitFeedbackOverNetwork}
                />
            </div>


            {uiBlocker}

        </div>
    )

}

const FeedbackSpacer = (props) => {
    return (
        <div className={"spacer"} />
    );
}

const FeedbackTitle = (props) => {

    const {title} = props;
    const {subtitle} = props;

    const rootElem = useRef();

    useEffect(() => {
        if (rootElem.current) {
            rootElem.current.focus();
        }
    }, []);

    return (
        <div
            className={"podcast-feedback-heading"}
            tabIndex={0}
            role={"heading"}
            aria-level={1}
            aria-label={Rosetta.string("podcast.feedback_accessibility_title", {title, subtitle})}
            autoFocus={true}
            ref={rootElem}
        >
            <div className={"podcast-feedback-heading-title"} tabIndex={-1} aria-hidden={true}>{title}</div>
            <div className={"podcast-feedback-heading-subtitle"} tabIndex={-1} aria-hidden={true}>{subtitle}</div>
        </div>
    );

}

const FeedbackRating = (props) => {

    const {label} = props;
    const {value} = props;
    const {callback} = props;

    const stars = [];
    for (let i = 0; i < 5; i++) {
        let graphic = iconStarEmpty;
        let accessibilityString = Rosetta.string("podcast.feedback_accessibility_star", { rating : (i + 1), total : 5});
        if (value > 0 && i < value) {
            graphic = iconStarFilled;
            accessibilityString = Rosetta.string("podcast.feedback_accessibility_radio_selected", { label : accessibilityString });
        }

        stars.push(
            <button
                className={"podcast-feedback-rating-star"}
                aria-label={accessibilityString}
                style={{
                    backgroundImage : ImageUtil.background(graphic)
                }}
                onClick={() => {
                    if (callback) {
                        callback("change", i + 1);
                    }
                }}
            />
        );
    }

    return (
        <div className={"podcast-feedback-rating"}>
            <label tabIndex={0}>{label}</label>
            <div className={"podcast-feedback-rating-star-container"}>
                <div className={"spacer"}/>
                {stars}
                <div className={"spacer"}/>
            </div>
        </div>
    )

}

const FeedbackRadio = (props) => {

    const {label} = props;
    const {options} = props;
    const {value} = props;
    const {columnCount} = props;
    const {callback} = props;

    // RENDER

    const radioRows = [];
    const radioColumns = columnCount ? columnCount : 3;

    if (options) {
        let currentRow = [];
        options.forEach((option) => {
            if (currentRow.length >= radioColumns) {
                radioRows.push(currentRow);
                currentRow = [];
            }

            let isSelected = false;
            let accessibilityLabel = option.label;
            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    if (value[i] === option.value) {
                        isSelected = true;
                        break;
                    }
                }
            } else {
                isSelected = value === option.value;
            }

            let classExtra = "";
            if (isSelected) {
                classExtra = " selected";
                accessibilityLabel = Rosetta.string("podcast.feedback_accessibility_radio_selected", { label : option.label });
            }

            currentRow.push(
                <button
                    className={"podcast-feedback-radio-item" + classExtra}
                    aria-label={accessibilityLabel}
                    onClick={() => {
                        if (callback) {
                            callback("change", option.value);
                        }
                    }}
                >
                    {option.label}
                </button>
            );
        });
        if (currentRow.length > 0) {
            radioRows.push(currentRow);
        }
    }

    return (
        <div className={"podcast-feedback-radio"}>
            <label tabIndex={0}>{label}</label>
            {
                radioRows.map((row) => (
                    <div className={"podcast-feedback-radio-container"}>
                        {row}
                    </div>
                ))
            }
        </div>
    );

}

const FeedbackButton = (props) => {

    const {label} = props;
    const {callback} = props;

    return (
        <div className={"podcast-feedback-button"}>
            <button onClick={(e) => {
                if (callback) {
                    callback(e);
                }
            }}>
               {label}
            </button>
        </div>
    );

}