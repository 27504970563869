/**
 * ROSETTA v1.0
 * ------------
 *
 * Rosetta is a Localisation/Internationalisation library for ReactJS.
 *
 * Rosetta sits as a Singleton instance in your ReactJS project and can arbitrarily load language files from the server
 * and display the strings in the UI.
 *
 * -------------
 * HOW TO LOAD STRINGS
 * -------------
 *
 * To support arbitrary locales, you can provide JSON files which contain JSON objects that define key-value pairs.
 *
 * These JSON files must be provided in a "locale" directory that sits inline with the Rosetta.js class (this file).
 *
 * Rosetta does not have an opinion on how you organise these locale files. You can use an ISO locale format such as
 * "enGB.json", "enUS.json", "deDE.json". In this case you can assign the locales "enGB" or "en-GB", etc. Note that when
 * loading the JSON files corresponding with the locale, Rosetta will remove any hyphen (-) characters, so do not name
 * your locale files with any hyphen characters. You can also just name your files such as "english.json", however this
 * is not recommended as languages such as English have multiple variations that it might be difficult to support later
 * if required.
 *
 * Since loading string files is a network task, you should build your app to be reactive to Rosetta's state. If you
 * call for strings while Rosetta is loading, you will end up with unexpected results. After loading a string file the
 * UI will need to be refreshed so that Rosetta strings can be re-loaded. You can achieve this by mutating a JSX key
 * high in the JSX DOM hierarchy, this will cause a recreation of all Components within that part of the DOM, which will
 * lead to re-calls to the Rosetta library with the newly loaded strings.
 *
 * The easiest way to manage Rosetta loading is to use the provided RosettaAwareComponent which manages two important
 * aspects of the Rosetta implementation for you: First, the component will register a callback which monitors the
 * Rosetta state for you, secondly, it can manage the UI to display while strings are being loaded. You can provide your
 * own loading screen to display while this occurs. Note that this will force a refresh of all content inside the
 * RosettaAwareComponent. If you need a more sophisticated approach to handling locale changes, you will need to monitor
 * Rosetta state yourself and update components yourself by assigning callbacks.
 *
 * ---------------
 * HOW TO DISPLAY STRINGS
 * ---------------
 *
 * Rosetta reads strings files in JSON format. The structure of the JSON dictates the calling identifier required to
 * pass. For example:
 *
 * { "common" : { "hello" : "world" } }
 *
 * To call the "hello" string, which contains the string "world", you would call like:
 *
 * Rosetta.string("common.hello"); // Output: "world"
 *
 * You can also use Rosetta's built-in templating solution to replace strings dynamically within the output string using
 * replace parameters. In your string, wrap replaceable strings in curly braces ({}), for example:
 *
 * { "common" : { "replace" : "hello {greet}" } }
 *
 * When calling for the string, simply provide an object with your replace parameters:
 *
 * Rosetta.string("common.replace", { greet : "world" }); // Output "hello world"
 *
 * ***
 *
 * If you wish to display a plural, you can use Rosetta's Plural formatter to get you there. Instead of defining a
 * string for a key, you instead define an array which has the following format:
 *
 * { "type" : "zero|one|two|many", "string" :  "(a string)" }
 *
 * You can define any one of the following types: "zero", "one", "two" or "many". The default is "many".
 *
 * To call for a Plural, you MUST provide the "pluralValue" parameter with a .string() call, for example:
 *
 * { "plurals" : { "hello" : [ { "type" : "one", "string" : "world"}, { "type" : "many", "string" : "worlds" } ] } }
 *
 * Rosetta.string("plurals.hello", null, 2); // Output: "worlds"
 *
 * You can also use templating with plurals:
 *
 * "replacePlural" : [ { "type" : "one", "string" : "{count} Notification" } ]
 *
 * Rosetta.string("...", { count : 1 }, 1); // Output: "1 Notification"
 *
 * ---------------
 * CALLBACKS
 * ---------------
 *
 * You can add Callbacks to Rosetta instances by using the addCallbacks function. Remember to remove Callbacks using
 * removeCallbacks().
 *
 * Callbacks will provide the current state of Rosetta. Rosetta will be in one of three states: Uninitialised (0),
 * Loading (1) or Ready (2). Only when Rosetta is Ready (2) should you attempt to call for strings. Doing so before the
 * state is Ready will result in a failure of the string() operation.
 *
 */
export default class Rosetta {

    static instance = null;

    static STATE_UNINITALISED = 0;
    static STATE_LOADING = 1;
    static STATE_READY = 2;

    state = 0;
    currentLocale = "en-GB";
    supportedLocales = ["en-GB", "jp-JP"];
    loadedStrings = {};
    static initialised = false;

    callbacks = [];

    /**
     * Initialise Rosetta, creates the Singleton instance and passes config data.
     *
     * Config params:
     *
     * currentLocale - Sets the current locale
     *
     * supportedLocales - Sets the currently supported locales
     *
     * @param config        Config data to load
     */
    static initialise(config) {
        let rosetta = Rosetta.getInstance();

        if (config !== undefined && config !== null) {
            if (config.hasOwnProperty("supportedLocales")) {
                rosetta.supportedLocales = config.supportedLocales;
            }

            if (config.hasOwnProperty("currentLocale")) {
                rosetta.curentLocale = config.currentLocale;
                rosetta.setLocale(rosetta.currentLocale);
            }
        }
    }

    static getInstance() {
        if (Rosetta.instance == null) {
            Rosetta.instance = new Rosetta();
            Rosetta.instance.loadStrings();
        }

        return this.instance;
    }

    getLocale = () => {
        return this.currentLocale;
    }

    setLocale = (locale) => {
        if (this.isLocaleSupported(locale)) {
            this.currentLocale = locale;
            this.loadStrings();
        }
    }

    isLocaleSupported = (locale) => {
        let out = false;
        this.supportedLocales.forEach((l) => {
            if (locale === l) {
                out = true;
                return false;
            }
        });

        return out;
    }

    loadStrings = (locale) => {
        if (locale === undefined) {
            locale = this.currentLocale;
        }

        let localeFix = locale.replace("-", "");

        this.setState(Rosetta.STATE_LOADING);

        import("./locale/" + localeFix + ".json").then(
            (strings) => {
                this.loadedStrings = strings;

                Rosetta.initialised = true;

                this.setState(Rosetta.STATE_READY);
            }
        )
    }

    setState = (state) => {
        this.state = state;
        this.triggerCallbacks();
    }

    getState = () => {
        return this.state;
    }

    getStrings = () => {
        return this.loadedStrings;
    }

    getString = (keyName, replaceParams, pluralValue) => {
        let out = keyName;

        let keyNameParts = keyName.split(".");
        let section = keyNameParts[0];
        let stringName = keyNameParts[1];

        if (this.loadedStrings[section] !== undefined) {
            if (this.loadedStrings[section][stringName] !== undefined) {
                out = this.loadedStrings[section][stringName];

                if (Array.isArray(out)) {
                    // If the "string" is an array, this is a Plural. Work out which plural to use now.
                    if (pluralValue !== undefined && !isNaN(pluralValue)) {
                        for (let i = 0; i < out.length; i++) {
                            if (out[i].hasOwnProperty("type") && out[i].hasOwnProperty("string")) {
                                if (out[i].type === "zero") { // Zero
                                    if (pluralValue === 0) {
                                        out = out[i].string;
                                        break;
                                    }
                                } else if (out[i].type === "one") { // One
                                    if (pluralValue === 1) {
                                        out = out[i].string;
                                        break;
                                    }
                                } else if (out[i].type === "two") { // Two
                                    if (pluralValue === 2) {
                                        out = out[i].string;
                                        break;
                                    }
                                } else if (out[i].type === "three") {
                                    if (pluralValue === 3) {
                                        out = out[i].string;
                                        break;
                                    }
                                } else { // Many
                                    out = out[i].string;
                                }
                            }
                        }
                    } else {
                        // If we have a plural but no way of identifying what plural to use, treat as error.
                        out = keyName;
                    }
                }

                // Manipulate the Replace Params to handle multi-customer localisation
                if (replaceParams === undefined) {
                    replaceParams = {};
                }

                let keys = Object.keys(replaceParams);

                if (keys !== undefined) {
                    for (let i = 0; i < keys.length; i++) {
                        let key = keys[i];
                        let value = replaceParams[key];

                        out = this.replaceAll(out, "{" + key + "}", value);
                    }
                }
            } else {
                console.log("ROSETTA: No string in category " + section + " of key: " + stringName);
            }
        } else {
            console.log("ROSETTA: No string category of the name " + section);
        }

        return out;
    }

    static string(keyName, replaceParams, pluralValue, defaultValue) {
        if (this.instance !== null) {
            return this.instance.getString(keyName, replaceParams, pluralValue);
        }
        if (defaultValue === undefined) {
            return keyName;
        } else {
            return defaultValue;
        }
    }

    replaceAll = (haystack, needle, replace) => {
        return haystack.replace(new RegExp(needle, "g"), replace);
    }

    static isInitialised = () => {
        return this.initialised;
    }

    addCallback = (callback) => {
        this.callbacks.push(callback);
    }

    removeCallback = (callback) => {
        for (let i = 0; i < this.callbacks.length; i++) {
            if (this.callbacks[i] === callback) {
                this.callbacks.splice(i, 1);
                break;
            }
        }
    }

    triggerCallbacks = () => {
        for (let i = 0; i < this.callbacks.length; i++) {
            try {
                this.callbacks[i](this.state);
            } catch (e) {
                console.log(e);
            }
        }
    }

}