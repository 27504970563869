export const Navigator = {
    _callbacks : [],
    _addCallback : (callback) => {
        Navigator._callbacks.push(callback);
    },
    _removeCallback : (callback) => {
        for (let i = Navigator._callbacks.length - 1; i >= 0; i--) {
            if (Navigator._callbacks[i] === callback) {
                Navigator._callbacks.splice(i, 1);
            }
        }
    },
    navigate : (element, data, popTop) => {
        if (popTop === undefined) {
            popTop = false;
        }

        let action = Navigator.actions.PUSH;
        if (popTop) {
            action = Navigator.actions.POP_PUSH;
        }

        Navigator._triggerCallbacks(
            action,
            {
                element,
                data
            }
        );
    },
    back : () => {
        Navigator._triggerCallbacks(
            Navigator.actions.POP
        );
    },
    clear : () => {
        Navigator._triggerCallbacks(
            Navigator.actions.CLEAR
        );
    },
    _triggerCallbacks : (action, data) => {
        for (let i = Navigator._callbacks.length - 1; i >= 0; i--) {
            try {
                Navigator._callbacks[i](action, data);
            } catch (e) {
                console.log(e);
            }
        }
    },
    actions : {
        /** Adds a screen to the stack */
        PUSH : 1,
        /** Removes the last screen from the stack */
        POP : 2,
        /** Clears all screens from the stack */
        CLEAR : 3,
        /** Removes top screen, adds another after **/
        POP_PUSH : 4,
    }
}