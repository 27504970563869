import {useEffect, useState} from "react";
import Rosetta from "../rosetta/Rosetta";
import {LoadingSpinner} from "../components/loading/LoadingSpinner";

export const RosettaComponent = (props) => {

    const {loadingComponent} = props;
    const {callback} = props;

    const [rosettaReady, setRosettaReady] = useState(false);

    useEffect(() => {
        const rosetta = Rosetta.getInstance();

        rosetta.addCallback((state) => {
            setRosettaReady(state === Rosetta.STATE_READY);
        })
    }, []);

    useEffect(() => {
        if (rosettaReady) {
            if (callback) {
                callback();
            }
        }
    }, [rosettaReady]);

    let immediatelyReady = Rosetta.getInstance().getState() === Rosetta.STATE_READY;

    if (!rosettaReady && !immediatelyReady) {
        let loadingUI = <LoadingSpinner />;
        if (loadingComponent !== undefined) {
            loadingUI = loadingComponent;
        }
        return loadingUI;
    }

    return props.children;

}