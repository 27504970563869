import "./AlertModal.css";
import {useEffect, useState} from "react";
import { v4 as uuidv4 } from "uuid";

export const BaseModalActions = {
    CLOSE : "close"
};

export const BaseModalSizes = {
    NORMAL : 1,
    LARGE : 2,
    XLARGE : 3
}

export const BaseModal = (props) => {

    const {shown} = props;
    const {forceDismiss} = props;
    const {size} = props;

    const [key, setKey] = useState(uuidv4);
    const [isDismissing, setIsDismissing] = useState(false);

    useEffect(() => {
        let dismissTimer = undefined;
        if (isDismissing) {
            clearTimeout(dismissTimer);
            dismissTimer = setTimeout(() => {
                setIsDismissing(false);
                handleCallback(BaseModalActions.CLOSE);
            }, 300);
        }

        return () => {
            clearTimeout(dismissTimer);
        }
    }, [isDismissing]);

    useEffect(() => {
        if (forceDismiss === true && !isDismissing) {
            setIsDismissing(true);
        }
    }, [forceDismiss]);

    function handleCallback(action) {
        if (props.callback !== undefined) {
            props.callback(action);
        }
    }

    if (!shown) return [];

    let useKey = "balm-" + key;
    let classExtra = "";
    if (isDismissing) {
        useKey += "-d";
        classExtra = " dismissing";
    }

    let sizeClass = "";
    if (size === BaseModalSizes.LARGE) {
        sizeClass = " large";
    } else if (size === BaseModalSizes.XLARGE) {
        sizeClass = " x-large";
    }

    return (
        <dialog
            open={true}
            className={"alert-modal" + classExtra}
            key={useKey}
        >
            <div className={"spacer"} />

            <div className={"container"}>
                <div className={"row justify-content-center"}>
                    <div className={"col-12 " + sizeClass}>
                        <div className={"alert-modal-dialog"}>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>

            <div className={"spacer"} />
        </dialog>
    )

}