import "./PodcastVolumeModal.css";
import Rosetta from "../../../rosetta/Rosetta";
import {MinimalSeekBar, MinimalSeekBarActions, MinimalSeekBarCallbackStrategies} from "../../ui/MinimalSeekBar";
import {useEffect, useRef, useState} from "react";

export const PodcastVolumeModalActions = {
    DISMISS : "podcastVolumeModal.dismiss",
    CHANGE : "podcastVolumeModal.change"
};

export const PodcastVolumeModal = (props) => {

    const {shown} = props;
    const {overallVolume} = props;
    const {podcastVolume} = props;
    const {backgroundVolume} = props;
    const {callback} = props;

    const [dismissing, setDismissing] = useState(false);
    const dismissTimeout = useRef();

    useEffect(() => {
        if (!shown) {
            clearTimeout(dismissTimeout.current);
            setDismissing(false);
        }
    }, [shown]);

    useEffect(() => {
        clearTimeout(dismissTimeout.current);
        dismissTimeout.current = setTimeout(() => {
            handleCallback(PodcastVolumeModalActions.DISMISS);
        }, 200);
    }, [dismissing]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function volumeDidChange(type, progress) {
        handleCallback(PodcastVolumeModalActions.CHANGE, {
            type,
            progress : (progress / 100)
        });
    }

    // RENDER

    if (!shown) return [];

    const volumeOptions = [
        {
            label : Rosetta.string("podcast.volume_overall"),
            type : "overall",
            value : overallVolume
        },
        {
            label : Rosetta.string("podcast.volume_voice"),
            type : "podcast",
            value : podcastVolume
        },
        {
            label : Rosetta.string("podcast.volume_background"),
            type : "background",
            value : backgroundVolume
        }
    ];

    const volumeElems = [];

    volumeOptions.forEach((option) => {
        volumeElems.push(
            <div className={"podcast-volume-modal-label"}>
                {option.label}
            </div>
        );

        volumeElems.push(
            <div className={"podcast-volume-modal-slider"}>
                <MinimalSeekBar
                    progress={option.value * 100}
                    barColour={"#a2a2a2"}
                    handleColour={"#4a75b7"}
                    callbackStrategy={MinimalSeekBarCallbackStrategies.ON_MOVE}
                    callback={(action, data) => {
                        if (action === MinimalSeekBarActions.CHANGE) {
                            volumeDidChange(option.type, data);
                        }
                    }}/>
            </div>
        );
    })

    let modalKey = "podcastvolumemodal";
    let dialogKey = "podcastvolumedialog";
    let classExtra = "";

    if (dismissing) {
        modalKey += "_dismissing";
        dialogKey += "_dismissing";
        classExtra = " dismissing";
    }

    return (
        <div className={"podcast-volume-modal" + classExtra} key={modalKey}>
            <div className={"spacer"}/>

            <div className={"podcast-volume-modal-dialog"} key={dialogKey}>
                <div className={"podcast-volume-modal-title"}>
                    {Rosetta.string("podcast.volume_title")}
                </div>

                {volumeElems}

                <button
                    className={"podcast-volume-modal-button"}
                    tabIndex={0}
                    aria-label={Rosetta.string("common.close")}
                    role={"button"}
                    onClick={() => { setDismissing(true) }}
                >
                    {Rosetta.string("common.close")}
                </button>
            </div>

            <div className={"spacer"}/>
        </div>
    )

}