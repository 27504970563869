import {useEffect, useRef, useState} from "react";
import {Dimensions} from "../../util/Dimensions";

export const ScreenStackActions = {
    SCREEN_UPDATE : "screenStackAction.screenUpdate"
};

export const ScreenStack = (props) => {

    const {screens} = props;
    const {callback} = props;

    useEffect(() => {
        if (callback) {
            callback(ScreenStackActions.SCREEN_UPDATE, screens);
        }
    }, [screens]);

    // RENDER

    const screenItems = [];

    if (screens) {
        for (let i = 0; i < screens.length; i++) {
            const sItem = screens[i];

            const accessibilityEnabled = i === screens.length - 1;

            if (sItem.hasOwnProperty("element")) {
                screenItems.push(
                    <ScreenStackItem
                        element={sItem.element}
                        accessibilityEnabled={accessibilityEnabled}
                        data={sItem.data}/>
                );
            }
        }
    }

    let containerWidth = (screenItems.length * 100) + "%";
    let containerMargin = ((screenItems.length - 1) * -100) + "%";

    return (
        <div className={"screen-stack"}>
            <div
                className={"screen-stack-container"}
                style={{
                    width: containerWidth,
                    marginLeft : containerMargin
                }}>
                {screenItems}
            </div>
        </div>
    )

}

export const ScreenStackItem = (props) => {

    const {element} = props;
    const {data} = props;
    const {accessibilityEnabled} = props;

    const [contentHidden, setContentHidden] = useState(false);

    const contentHiddenTimeout = useRef();

    useEffect(() => {
        clearTimeout(contentHiddenTimeout.current);
        if (!accessibilityEnabled) {
            contentHiddenTimeout.current = setTimeout(() => {
                setContentHidden(true);
            }, 210);
        } else {
            setContentHidden(false);
        }
    }, [accessibilityEnabled]);

    const cssStyle = {};

    if (Dimensions.screen.width) {
        cssStyle.width = Dimensions.screen.width + "px";
    }

    // Work out if this screen should be interactable by screen readers
    // We have a problem that if this is NOT set, screen readers will
    // continue to iterate through items on screens that are not
    // visible to the end user. The screen in the foreground should
    // not have any tab index or aria-hidden value set and should assume
    // default behaviour.
    let tabIndex = undefined;
    let ariaHidden = undefined;
    if (!accessibilityEnabled) {
        tabIndex = -1;
        ariaHidden = true;
    }

    let stackItemClass = "";
    if (contentHidden) {
        stackItemClass = " screen-stack-hidden";
    }

    return (
        <div
            className={"screen-stack-item" + stackItemClass}
            tabIndex={tabIndex}
            aria-hidden={ariaHidden}
            style={cssStyle}
        >
            {element}
        </div>
    );
}