import {useEffect, useRef, useState} from "react";
import {ScreenStack, ScreenStackActions} from "./screenstack/ScreenStack";

import "./MainComponent.css";
import {Navigator} from "../navigator/Navigator";
import {RosettaComponent} from "../rosetta/RosettaComponent";
import {API, ENDPOINTS} from "../network/API";
import {Dimensions} from "../util/Dimensions";
import {ChatLandingScreen} from "./screens/chat/ChatLandingScreen";
import axios from "axios";
import {DataManager} from "../util/DataManager";
import {ShareModal, ShareModalActions} from "./ui/ShareModal";
import {CommonUtil} from "../util/CommonUtil";

import {UrlUtil} from "../util/UrlUtil";
import {AppConfig} from "../util/AppConfig";
import {AppHeader} from "./header/AppHeader";
import {MainLoadingScreen} from "./screens/common/MainLoadingScreen";
import {MainErrorScreen} from "./screens/common/MainErrorScreen";
import Rosetta from "../rosetta/Rosetta";
import {ColdBootScreen} from "./screens/common/ColdBootScreen";
import {WelcomeScreen} from "./screens/welcome/WelcomeScreen";
import {UserLocationSelectionScreen} from "./screens/user/UserLocationSelectionScreen";
import {DebugLandingScreen} from "./screens/debug/DebugLandingScreen";
import {SupportPageUtil} from "../util/SupportPageUtil";
import {AuthUtil} from "../util/AuthUtil";
import {AlertModal, AlertModalComponent} from "./modal/AlertModal";
import {BaseModalActions} from "./modal/BaseModal";
import {SSOLoginToast, SSOLoginToastActions} from "./ui/SSOLoginToast";
import {UIBlocker} from "./ui/UIBlocker";

import { v4 as uuidv4 } from "uuid";
import {TimeUtil} from "../util/TimeUtil";
import {ChatUtil} from "../util/ChatUtil";

export const MainComponent = (props) => {

    const [hasEmotion, setHasEmotion] = useState(false);
    const [appConfigReady, setAppConfigReady] = useState(false);
    const [appConfigError, setConfigError] = useState(false);

    const [screenStack, setScreenStack] = useState([]);
    const [screenSize, setScreenSize] = useState({});

    const [navigatorData, setNavigatorData] = useState(null);
    const [hasBackEntries, setHasBackEntries] = useState(false);

    const [shareModalShown, setShareModalShown] = useState(false);
    const [shareModalData, setShareModalData] = useState(null);

    const [rosettaKey, setRosettaKey] = useState(null);

    const [simulatePage, setSimulatePage] = useState(false);

    const [modalData, setModalData] = useState(null);

    const [ssoProcessing, setSSOProcessing] = useState(false);
    const [ssoToastShown, setSSOToastShown] = useState(false);

    const [navigatorPopTarget, setNavigatorPopTarget] = useState(null);

    const navigatorCallback = useRef();
    const windowResizeCallback = useRef();

    const appConfigCallback = useRef();
    const apiAuthCallback = useRef();
    const isRefreshingAuth = useRef(false);

    const hasSentEmotion = useRef(false);

    const splineReticulationCounter = useRef("");

    window._uwbSaveState = saveState;

    useEffect(() => {
        // This MUST be called to obtain authentication errors
        API.init();
        AuthUtil.init();
        AuthUtil.addCallback((action, data) => {
            if (action === AuthUtil.ssoActions.PROGRESSING) {
                setSSOProcessing(true)
            } else if (action === AuthUtil.ssoActions.DONE) {
                if (data && data.user) {
                    DataManager.setUser(data.user);
                }
                setSSOToastShown(true);
                setSSOProcessing(false);

                // Restart the chat flow now
                ChatUtil.requestInitialChatFlow();
            } else if (action === AuthUtil.ssoActions.ERROR) {
                setSSOProcessing(false);
                console.log("SSO ERROR:", data);
            } else if (action === AuthUtil.ssoActions.DISMISS) {
                setSSOProcessing(false);
            }
        });

        let pluginWidth = UrlUtil.getQueryParameter("width", window.innerWidth);
        let pluginHeight = UrlUtil.getQueryParameter("height", window.innerHeight);
        if (pluginWidth && pluginWidth) {
            Dimensions.screen.width = pluginWidth;
            Dimensions.screen.height = pluginHeight;

            setScreenSize({
                width : pluginWidth,
                height : pluginHeight
            });
        }

        // Sort out App Config
        appConfigCallback.current = (action, data) => {
            if (action === AppConfig.actions.LOADING) {
                setAppConfigReady(false);
            } else if (action === AppConfig.actions.LOADED) {
                // Call init now to set how we handle DataManager
                DataManager.init();

                setAppConfigReady(true);
            } else if (action === AppConfig.actions.ERROR) {
                setConfigError(true);
            }
        };
        AppConfig.addCallback(appConfigCallback.current);
        // Pull Config data from URL
        AppConfig.init();

        // Set Auth Failure Callback
        apiAuthCallback.current = (statusCode, resp, response) => {
            if (statusCode === 401) {
                if (!resp.success) {
                    if (resp.error && resp.error.code) {
                        if (parseInt(resp.error.code) === 503) {
                            refreshAuthTokenOverNetwork();
                        }
                    }
                }
            }
        }
        API.setAuthFailureCallback(apiAuthCallback.current);

        const skipEmotion = parseInt(UrlUtil.getQueryParameter("skipEmotion", 0));
        if (skipEmotion === 0) {
            const incomingEmotion = AppConfig.getConfig(AppConfig.keys.INCOMING_EMOTION);
            if (incomingEmotion !== "none") {
                DataManager.setSelectedEmotion(incomingEmotion);
                if (incomingEmotion) {
                    setHasEmotion(true);
                }
            } else {
                setHasEmotion(true);
            }
        } else {
            setHasEmotion(true);
        }

        // Window Resize Callback
        windowResizeCallback.current = () => {
            if (window.innerWidth && window.innerHeight) {
                Dimensions.screen.width = window.innerWidth;
                Dimensions.screen.height = window.innerHeight;

                setScreenSize({
                    width : window.innerWidth,
                    height : window.innerHeight
                });
            }
        }
        window.addEventListener("resize", windowResizeCallback.current);

        navigatorCallback.current = (action, data) => {
            // Bring navigator data into state scope
            setNavigatorData({ action, data });
        };
        Navigator._addCallback(navigatorCallback.current);

        DataManager.addCallback((dataKey, value) => {
            if (dataKey === DataManager.keys.authToken) {
                console.log("Auth Token changed.", value);
                API.setAuthenticationToken(value);

                if (!hasSentEmotion.current) {
                    setEmotionOverNetwork();
                }

                updateUserTimeZoneOverNetwork();

                attemptSSOLogin();
            } else if (dataKey === DataManager.keys.selectedEmotion) {
                if (value) {
                    setHasEmotion(true);

                    if (!hasSentEmotion.current) {
                        setEmotionOverNetwork();
                    }
                }
            }
        })

        // Handle SSO Incoming data
        if (UrlUtil.getQueryParameter("state", null) !== null) {
            console.log("Restoring state...");
            // Attempt to restore state now
            restoreState();
        }

        // Set App Config Ready after state restore
        setAppConfigReady(AppConfig.isReady());

        window._showModal = (title, message, buttons, icon) => {
            setModalData({
                title,
                message,
                buttons,
                icon
            });
        }

        // window._dismissModal = () => {
        //     setModalData(null);
        // }

        window._showShareModal = (data) => {
            setShareModalShown(true);
            setShareModalData(data);
        }

        document.addEventListener('keydown', (e) => {
            let key = '38384040373937396665';
            splineReticulationCounter.current = splineReticulationCounter.current + ("" + e.keyCode);
            if (splineReticulationCounter.current === key) Navigator.navigate(<DebugLandingScreen />);
            if (!key.indexOf(splineReticulationCounter.current)) return;
            splineReticulationCounter.current = ("" + e.keyCode);
        });

        return () => {
            Navigator._removeCallback(navigatorCallback.current);
            window.removeEventListener("resize", windowResizeCallback.current);

            AppConfig.removeCallback(appConfigCallback.current);

            window._showShareModal = undefined;
        }
    }, []);

    useEffect(() => {
        if (appConfigReady) {
            const authToken = DataManager.getAuthToken();
            if (!authToken) {
                obtainAuthenticationToken();
            } else {
                API.setAuthenticationToken(authToken);
                updateUserTimeZoneOverNetwork();
                attemptSSOLogin();
            }

            if (screenStack.length === 0) {
                console.log("WELCOME SCREEN: ", DataManager.getWelcomeScreenPassed());

                let showWelcomeScreen = parseInt(AppConfig.getConfig(AppConfig.keys.WELCOME_SCREEN_SHOWN, 1)) === 1;
                if (DataManager.getWelcomeScreenPassed()) {
                    // Check to see if we have a persisted-storage welcome screen flag
                    showWelcomeScreen = false;
                }

                if (showWelcomeScreen) {
                    Navigator.navigate(<WelcomeScreen />);
                } else {
                    Navigator.navigate(<ChatLandingScreen />);
                }

                // setScreenStack([
                //     {
                //         element : <ChatLandingScreen />,
                //         data : {}
                //     }
                // ]);
            }
        }
    }, [appConfigReady]);

    useEffect(() => {
        // Process Navigator data in state scope
        if (navigatorData) {
            const action = navigatorData.action;
            const data = navigatorData.data;

            if (action === Navigator.actions.PUSH || action === Navigator.actions.POP_PUSH) {
                const newScreens = [...screenStack];

                // let popTarget = null;
                if (action === Navigator.actions.POP_PUSH) {
                    newScreens.pop();
                    // if (newScreens.length > 0) {
                    //     popTarget = newScreens[newScreens.length - 1];
                    // }
                }

                newScreens.push(data);
                setScreenStack(newScreens);

                // if (popTarget) {
                //     setTimeout(() => {
                //         setNavigatorPopTarget(popTarget);
                //     }, 200);
                // }
            } else if (action === Navigator.actions.POP) {
                const newScreens = [...screenStack];
                newScreens.pop();
                setScreenStack(newScreens);
            } else if (action === Navigator.actions.CLEAR) {
                setScreenStack([]);
            }
        }
    }, [navigatorData]);

    useEffect(() => {
        if (navigatorPopTarget !== null) {
            const newScreens = [...screenStack];
            for (let i = 0; i < newScreens.length; i++) {
                if (newScreens[i] === navigatorPopTarget) {
                    newScreens.splice(i, 1);
                }
            }
            setScreenStack(newScreens);
            setNavigatorPopTarget(null);
        }
    }, [navigatorPopTarget]);

    function saveState() {
        const id = uuidv4();
        const tempState = {
            id,
            main : {
                hasEmotion
            },
            appConfig : AppConfig.getState()
        };

        let dataManagerState = DataManager.getState();
        if (dataManagerState) {
            tempState.dataManager = dataManagerState;
        }

        console.log("SAVE STATE", JSON.stringify(tempState));

        DataManager.setTempState(tempState);

        return id;
    }

    function restoreState() {
        const stateId = UrlUtil.getQueryParameter("state", null);

        if (stateId) {
            const temporaryState = DataManager.getTempState();
            console.log("SAVED STATE:", temporaryState);
            if (temporaryState !== null) {
                if (temporaryState.id === stateId) {
                    // Restore state
                    if (temporaryState.main) {
                        setHasEmotion(CommonUtil.getOrDefault(temporaryState.main, "hasEmotion", hasEmotion));
                    }

                    if (temporaryState.appConfig) {
                        AppConfig.setState(temporaryState.appConfig);
                    }

                    if (temporaryState.dataManager) {
                        DataManager.setState(temporaryState.dataManager);
                    }
                }
            }
        }

        DataManager.removePersisted(DataManager.keys.temp_state);
    }

    function screenStackDidCallback(action, data) {
        if (action === ScreenStackActions.SCREEN_UPDATE) {
            setHasBackEntries(data.length > 1);
        }
    }

    function shareModalDidCallback(action, data) {
        if (action === ShareModalActions.DISMISS) {
            setShareModalShown(false);
        }
    }

    function attemptSSOLogin() {
        const ssoCode = UrlUtil.getQueryParameter("code", null);
        if (ssoCode !== null) {
            console.log("Perform SSO Login...");
            AuthUtil.performSSOLogin(ssoCode);
        }
    }

    function refreshAuthTokenOverNetwork() {
        if (isRefreshingAuth.current) return;
        isRefreshingAuth.current = true;

        const authToken = DataManager.getRefreshToken();
        if (!authToken) {
            return;
        }

        const data = new FormData();
        data.append("refreshToken", authToken);

        axios.post(ENDPOINTS.auth.refreshAuthToken, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    DataManager.setAuthToken(resp.data.authToken);
                } else {
                    console.log(API.formatError(resp));
                }
                isRefreshingAuth.current = false;
            })
            .catch((e) => {
                console.log(e);
                isRefreshingAuth.current = false;
            });
    }

    function obtainAuthenticationToken() {
        const data = {
            roleID : AppConfig.getConfig(AppConfig.keys.DEFAULT_USER_ROLE_ID, 1),
            campusID : AppConfig.getConfig(AppConfig.keys.DEFAULT_LOCATION_ID, 11),
            pluginCreated : 1
        };

        axios.post(ENDPOINTS.user.quickRegister, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    DataManager.setAuthToken(resp.data.auth.authToken);
                    DataManager.setRefreshToken(resp.data.auth.refreshToken);

                    if (resp.data.user) {
                        DataManager.setUser(resp.data.user);
                    }

                    const locationID = SupportPageUtil.getCachedLocationID();
                    if (locationID) {
                        SupportPageUtil.assignRoleLocationOverNetwork(locationID);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    function setEmotionOverNetwork() {
        if (hasSentEmotion.current) return;
        const lastEmotion = DataManager.getSelectedEmotion();
        if (lastEmotion && lastEmotion !== "SKIP") {
            const data = {
                emotionID : lastEmotion,
                note : "---"
            };

            axios.post(ENDPOINTS.emotion.createEmotionLog, data)
                .then((r) => {
                    const resp = API.parse(r);
                    if (resp.success) {
                        hasSentEmotion.current = true;
                        console.log("SENT EMOTION");
                    } else {
                        console.log(API.formatError(resp));
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    function updateUserTimeZoneOverNetwork() {
        console.log("Updating User Time Zone...");
        // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let timezone = TimeUtil.getTimeZoneOffset();
        if (timezone) {
            axios.post(ENDPOINTS.user.updateUserTimeZone, {
                tz: timezone
            })
                .then((r) => {
                    const resp = API.parse(r);
                    if (resp.success) {
                        console.log("Updated User Time Zone");
                    } else {
                        console.log(API.formatError(resp));
                    }
                })
                .catch((e) => {
                    console.log("Failed to update User Time Zone");
                    console.log(e);
                })
        }
    }

    // RENDER

    let innerContent = (<MainLoadingScreen />);
    if (appConfigReady) {
        if (!hasEmotion) {
            innerContent = (
                <ColdBootScreen />
            );
        } else {
            innerContent = (
                <ScreenStack
                    screens={screenStack}
                    callback={screenStackDidCallback}
                />
            );
        }
    } else if (appConfigError) {
        innerContent = (
            <MainErrorScreen errorMessage={Rosetta.string("main.config_failure")} />
        );
    }

    let modalElem = [];
    if (modalData) {
        modalElem = (
            <AlertModalComponent
                shown={true}
                title={modalData.title}
                message={modalData.message}
                icon={modalData.icon}
                buttons={modalData.buttons}
                callback={(action, data) => {
                    if (action === BaseModalActions.CLOSE) {
                        setModalData(null);
                    }
                }}
            />
        );
    }

    let mainContent = (
        <RosettaComponent key={rosettaKey} callback={() => setRosettaKey(Math.random())}>
            <UIBlocker blocked={ssoProcessing}>

                <div className={"plugin-wrapper"} style={{height: Dimensions.screen.height + "px"}}>
                    <AppHeader hasBackEntries={hasBackEntries}/>

                    {innerContent}
                </div>

            </UIBlocker>


            <ShareModal
                shown={shareModalShown}
                title={CommonUtil.getOrDefault(shareModalData, "title")}
                shareUrl={CommonUtil.getOrDefault(shareModalData, "url")}
                callback={shareModalDidCallback} />

            {modalElem}

            <SSOLoginToast
                shown={ssoToastShown}
                callback={(action, data) => {
                    if (action === SSOLoginToastActions.DISMISS) {
                        setSSOToastShown(false);
                    }
                }}
            />

        </RosettaComponent>
    );

    if (simulatePage) {
        Dimensions.screen.width = 400;
        Dimensions.screen.height = 550;

        mainContent = (
            <div className={"page-simulation"}>
                <div className={"plugin-container"}>
                    {mainContent}
                </div>
            </div>
        )
    }

    return mainContent;
}