import "./UserLocationSelectionScreen.css";
import {AppConfig} from "../../../util/AppConfig";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import Rosetta from "../../../rosetta/Rosetta";
import {ServiceUtil} from "../../../util/ServiceUtil";
import {SupportPageUtil} from "../../../util/SupportPageUtil";
import {Navigator} from "../../../navigator/Navigator";
import {SupportListScreen} from "../support/SupportListScreen";

export const UserLocationSelectionScreen = (props) => {

    const [networkInFlight, setNetworkInFlight] = useState(false);
    const [locations, setLocations] = useState([]);

    const headerElem = useRef();

    useEffect(() => {
        fetchLocationsFromNetwork();

        if (headerElem.current) {
            headerElem.current.focus();
        }
    }, []);

    function onLocationSelected(location) {
        if (location) {
            SupportPageUtil.assignRoleLocationOverNetwork(location.id, (action, data) => {
                if (action === "finished") {
                    SupportPageUtil.populateLocationIntoCache(location);

                    if (ServiceUtil._temporaryServiceData) {
                        ServiceUtil.resolveServiceAction(ServiceUtil._temporaryServiceData, undefined, true);
                    } else if (SupportPageUtil._temporarySupportData) {
                        Navigator.navigate(
                            <SupportListScreen
                                supportTypeId={SupportPageUtil._temporarySupportData.sectionTypeID}
                                parentId={SupportPageUtil._temporarySupportData.parentID}
                            />,
                            undefined,
                            true
                        );
                    } else {
                        Navigator.back();
                    }
                } else if (action === "error" || action === "exception") {
                    console.log(data);
                }
            })
        }
    }

    function fetchLocationsFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        axios.get(ENDPOINTS.organisation.getCampuses)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setLocations(resp.data.campuses);
                } else {
                    console.log(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
            });
    }



    // RENDER

    const headerColour = AppConfig.getConfig(AppConfig.keys.HEADER_BACKGROUND_COLOUR, "#EFEFEF");
    const headerTheme = AppConfig.getConfig(AppConfig.keys.HEADER_THEME, "dark");

    let mainContent = [];
    if (networkInFlight) {
        mainContent = (<LoadingSpinner block={true} alignCentre={true} />);
    } else {
        if (locations.length > 0) {
            mainContent = locations.map((location) => (
                <li
                    className={"selection-list-item"}
                    tabIndex={0}
                    role={"button"}
                    aria-label={location.name}
                    onClick={() => {
                        onLocationSelected(location);
                    }}
                >
                    {location.name}
                </li>
            ))

            mainContent = (
                <ul className={"selection-list"}>
                    {mainContent}
                </ul>
            )
        } else {
            mainContent = (
                <div className={"empty-message"}>
                    {Rosetta.string("common.empty_message")}
                </div>
            );
        }
    }

    const headerText = Rosetta.string("role_location.select_campus");

    return (
        <div className={"user-location-selection-screen"}>
            <div
                className={"selection-heading " + headerTheme}
                tabIndex={0}
                aria-label={headerText}
                role={"heading"}
                aria-level={1}
                ref={headerElem}
                autoFocus={true}
            >
                <div className={"circular-presentation"} style={{backgroundColor : headerColour}} />
                <div className={"section-heading-text"}>{headerText}</div>
            </div>

            {mainContent}

        </div>
    )

}