import "./UnimplementedDeadendScreen.css";
import Rosetta from "../../../rosetta/Rosetta";
import {API} from "../../../network/API";
import {CommonUtil} from "../../../util/CommonUtil";

export const UnimplementedDeadendScreen = (props) => {

    function launchDownloadLink() {
        CommonUtil.openWindow(API.getAppDownloadUrl());
    }

    return (
        <div className={"unimplemented-deadend-screen"}>
            <div className={"spacer"} />

            <div className={"deadend-subtitle"}>{Rosetta.string("unimplemented.subtitle")}</div>
            <div className={"deadend-button"} onClick={launchDownloadLink}>
                <button className={"uwb-button inverted"}>{Rosetta.string("unimplemented.action")}</button>
            </div>

            <div className={"spacer"} />
        </div>
    )

}