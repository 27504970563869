import {AppConfig} from "../../util/AppConfig";

import iconCharley from "../../assets/charley_wave_full.svg";
import Rosetta from "../../rosetta/Rosetta";
import {ImageUtil} from "../../util/ImageUtil";

export const LaunchWidget = (props) => {

    const {callback} = props;

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    let widgetIcon = [];
    if (parseInt(AppConfig.getConfig(AppConfig.keys.WIDGET_SHOW_ICON, 1)) === 1) {
        const useWidgetIcon = AppConfig.getConfig(AppConfig.keys.WIDGET_ASSISTANT_IMAGE_URL, iconCharley);
        widgetIcon = (
            <div className={"uwbp-widget-content-icon"} style={{backgroundImage: ImageUtil.background(useWidgetIcon)}}/>
        )
    }

    const mainLabel = AppConfig.getConfig(AppConfig.keys.WIDGET_TITLE, Rosetta.string("common.app_name"));

    return (
        <div className={"uwbp-widget-container " + AppConfig.getConfig(AppConfig.keys.WIDGET_ALIGNMENT, "left")} onClick={() => handleCallback("click")}>
            <div className={"uwbp-widget"} tabIndex={0} aria-label={mainLabel} role={"button"} style={{
                borderColor: AppConfig.getConfig(AppConfig.keys.WIDGET_BORDER_COLOUR, "#000"),
                backgroundColor: AppConfig.getConfig(AppConfig.keys.WIDGET_BACKGROUND_COLOUR, "#FFF")
            }}>
                <div className={"uwbp-widget-content"}>
                    <div
                        className={"uwbp-widget-content-title"}
                        style={{color: AppConfig.getConfig(AppConfig.keys.WIDGET_TEXT_COLOUR, "#000")}}
                    >
                        {mainLabel}
                    </div>
                    {widgetIcon}
                </div>
            </div>
        </div>
    );

}