import QRCode from "react-qr-code";

export const ChatHeaderQRCode = (props) => {

    const {url} = props;

    return (
        <QRCode
            value={url}
            size={50}
        />
    )

}