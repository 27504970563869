import './App.css';
import {MainComponent} from "./components/MainComponent";

function App() {
  return (
    <MainComponent />
  );
}

export default App;
