export const ChatUtil = {
    messageTypes : {
        USER_RESPONSE : -1,
        MESSAGE : 1,
        ANSWERS : 2,
        SYSTEM_NOTIFICATION : 3,
        TEXT_FIELD : 4,
        IMAGE : 5,
        SERVICE_LINK : 6,
        APP_STORE_REVIEW : 7,
        ANIMATED_SVG : 8
    },
    createUserResponseItem : (text) => {
        return ({
            typeID : ChatUtil.messageTypes.USER_RESPONSE,
            data : {
                text
            }
        })
    },
    createServerResponseItem : (text, isHTML) => {
        return({
            typeID : ChatUtil.messageTypes.MESSAGE,
            isHTML,
            data : {
                text
            }
        });
    },
    requestInitialChatFlow : () => {
        if (window._uwbInitialChatFlow) {
            window._uwbInitialChatFlow();
        }
    }
}