import {AppConfig} from "../../../util/AppConfig";
import {ImageUtil} from "../../../util/ImageUtil";

import "./ChatListScreen.css";

import iconIndicator from "../../../assets/chevron_forward.svg";
import Rosetta from "../../../rosetta/Rosetta";
import {Navigator} from "../../../navigator/Navigator";

export const ChatListScreen = (props) => {

    function chatFlowWasSelected(chatFlow) {
        if (window._uwbPluginChangeChatFlow !== undefined) {
            window._uwbPluginChangeChatFlow(chatFlow.id);
        }
        Navigator.back();
    }

    let chatListElems = [];
    const data = JSON.parse(AppConfig.getConfig(AppConfig.keys.CHAT_FLOWS, "[]"));
    if (data && Array.isArray(data)) {
        chatListElems = data.map((item) => (
            <div
                className={"chat-list-item"}
                tabIndex={0}
                role={"button"}
                aria-label={Rosetta.string("chat.accessibility_chat_list_option", { message : item.title })}
                onClick={() => chatFlowWasSelected(item)}
            >
                <div className={"chat-list-item-content"}>
                    <div className={"chat-list-item-title"} tabIndex={-1} aria-hidden={true}>
                        {item.title}
                    </div>
                </div>

                <div className={"chat-list-item-icon"} style={{backgroundImage : ImageUtil.background(iconIndicator)}} />
            </div>
        ));
    }

    if (chatListElems.length === 0) {
        chatListElems.push(
            <div className={"empty-message"}>
                {Rosetta.string("common.empty_message")}
            </div>
        );
    }

    const headerBackgroundColour = AppConfig.getConfig(AppConfig.keys.HEADER_BACKGROUND_COLOUR, "");
    const headerTheme = AppConfig.getConfig(AppConfig.keys.HEADER_THEME, "");

    let headerClassExtra = AppConfig.getConfig(AppConfig.keys.HEADER_THEME, "dark");

    return (
        <div className={"chat-list-screen"}>
            <div className={"chat-list-header " + headerTheme + " " + headerClassExtra} style={{backgroundColor : headerBackgroundColour}}>
                {Rosetta.string("chat.list_title")}
            </div>

            {chatListElems}
        </div>
    )

}