import {useEffect, useRef, useState} from "react";
import {LaunchWidget} from "../../ui/LaunchWidget";
import {ImageUtil} from "../../../util/ImageUtil";

import "./ColdBootScreen.css";

import iconEmotionSmile from "../../../assets/ic_smile_emoji.svg";
import iconEmotionNeutral from "../../../assets/ic_neutral_emoji.svg";
import iconEmotionSad from "../../../assets/ic_sad_emoji.svg";
import {DataManager} from "../../../util/DataManager";
import {AppConfig} from "../../../util/AppConfig";
import {UrlUtil} from "../../../util/UrlUtil";
import Rosetta from "../../../rosetta/Rosetta";
import {ThemedButton} from "../../ui/ThemedButton";

export const ColdBootScreen = (props) => {

    const [switching, setSwitching] = useState(false);
    const [switched, setSwitched] = useState(false);

    const switchTimeout = useRef();

    const launchMode = useRef();

    useEffect(() => {
        // If we are launching from a popup, skip the widget display
        // and just show the emotion picker immediately.
        launchMode.current = UrlUtil.getQueryParameter("launchMode");

        if (launchMode.current === "popup") {
            // setSwitched(true);
            // 03/05 - No longer show emotions at all in popup mode
            DataManager.setSelectedEmotion("SKIP");
        }
    }, []);

    useEffect(() => {
        if (switching) {
            setSwitched(true);
            clearTimeout(switchTimeout.current);
            switchTimeout.current = setTimeout(() => {
                setSwitching(false);
            }, 200);
        }
    }, [switching]);

    function widgetWasClicked() {
        if (parseInt(AppConfig.getConfig(AppConfig.keys.WIDGET_SHOW_EMOTIONS, 1)) === 1) {
            // Show emotions
            setSwitched(true);
        } else {
            // Skip emotions and move on
            DataManager.setSelectedEmotion("SKIP");
        }
    }

    // RENDER

    const widgetElem = <LaunchWidget callback={widgetWasClicked} />
    const emotionElem = <EmotionInput />;

    let mainContent = [];

    if (switching) {
        mainContent.push(<div className={"switchable switching-out"}>{widgetElem}</div>);
        mainContent.push(<div className={"switchable switching-in"}>{emotionElem}</div>);
    } else if (!switched) {
        mainContent.push(widgetElem);
    } else {
        mainContent.push(emotionElem);
    }

    return (
        <div className={"cold-boot-screen"}>
            {mainContent}
        </div>
    )

}

const EmotionInput = (props) => {

    const icons = [
        { id : AppConfig.emotions.EMOTION_HAPPY, graphic : iconEmotionSmile, label : Rosetta.string("main.emotion_happy") },
        { id : AppConfig.emotions.EMOTION_NEUTRAL, graphic : iconEmotionNeutral, label : Rosetta.string("main.emotion_neutral") },
        { id : AppConfig.emotions.EMOTION_SAD, graphic : iconEmotionSad, label : Rosetta.string("main.emotion_sad") }
    ];

    function setEmotion(emotion) {
        if (emotion) {
            DataManager.setSelectedEmotion(emotion.id);
        }
    }

    return (
        <div className={"emotion-container"}>
            <div className={"emotion-title"}>
                {Rosetta.string("main.cold_boot_how_feeling")}
            </div>

            <div className={"emotion-icons"}>
                <div className={"spacer"}/>

                {icons.map((emotion) => (
                    <EmotionItem
                        graphic={emotion.graphic}
                        label={emotion.label}
                        callback={() => {
                            setEmotion(emotion)
                        }}
                    />
                ))}

                <div className={"spacer"}/>
            </div>

            <div className={"emotion-button"}>
                <ThemedButton
                    label={Rosetta.string("common.skip")}
                    onClick={() => {
                        setEmotion({ id : "SKIP" });
                    }}
                />
            </div>
        </div>
    );
}

const EmotionItem = (props) => {

    const {graphic} = props;
    const {callback} = props;
    const {label} = props;

    function handleCallback() {
        if (callback) {
            callback();
        }
    }

    let accessibilityString = Rosetta.string("main.accessibility_feeling", { emotion : label })

    return (
        <div
            className={"emotion-item"}
            tabIndex={0}
            aria-label={accessibilityString}
            role={"button"}
            onClick={() => handleCallback()}
        >
            <div className={"image"} style={{backgroundImage : ImageUtil.background(graphic)}} />
        </div>
    )

}