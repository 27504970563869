import {useEffect, useState} from "react";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import {ImageUtil} from "../../../util/ImageUtil";
import Rosetta from "../../../rosetta/Rosetta";

import "./SupportListScreen.css";
import axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {ServiceUtil} from "../../../util/ServiceUtil";
import {CommonUtil} from "../../../util/CommonUtil";

export const SupportListScreen = (props) => {

    const {supportTypeId} = props;
    const {parentId} = props;

    const [supportPage, setSupportPage] = useState();
    const [networkInFlight, setNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchSupportPageFromNetwork();
    }, []);

    function moveToService(serviceId) {
        ServiceUtil.resolveServiceAction(serviceId);
    }

    function fetchSupportPageFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let url = "";
        if (supportTypeId) {
            url += "sectionTypeID=" + supportTypeId;
        }

        if (parentId) {
            if (url !== "") {
                url += "&";
            }
            url += "id=" + parentId;
        }

        if (url !== "") {
            url = "?" + url;
        }
        url = ENDPOINTS.service.getAppService + url;

        axios.get(url)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSupportPage(resp.data.services);
                } else {
                    console.log(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // RENDER

    let mainContent = [];
    if (networkInFlight) {
        mainContent = (
            <div className={"loading-container"}>
                <LoadingSpinner alignCentre={true}/>
            </div>
        );
    } else {
        if (supportPage && supportPage.length > 0) {
            let lastHeading = null;

            for (let i = 0; i < supportPage.length; i++) {
                let item = supportPage[i];

                if (item.hasOwnProperty("sectionTitle")) {
                    if (item.sectionTitle !== lastHeading) {
                        if (item.sectionTitle) {
                            mainContent.push(
                                <SupportHeading text={item.sectionTitle}/>
                            );
                        }
                        lastHeading = item.sectionTitle;
                    }

                    mainContent.push(
                        <SupportItem
                            item={item}
                            callback={(action, data) => {
                                if (action === "click") {
                                    moveToService(data.serviceID);
                                }
                            }}
                        />
                    );
                }
            }
        } else {
            mainContent.push(
                <div className={"empty-message"}>{Rosetta.string("common.empty_message")}</div>
            );
        }
    }

    return (
        <div className={"support-list-screen"}>
            {mainContent}
        </div>
    )

}

const SupportHeading = (props) => {
    const {text} = props;

    return (
        <div
            className={"support-page-heading"}
            tabIndex={0}
            aria-label={text}
            role={"heading"}
            aria-level={1}
        >
            <span tabIndex={-1} aria-hidden={true}>{text}</span>
        </div>
    )
}

const SupportItem = (props) => {
    const {item} = props;
    const {callback} = props;

    let imageElem = [];
    if (item.hasOwnProperty("imageURL")) {
        imageElem = (
            <div className={"support-page-item-image"} style={{backgroundImage : ImageUtil.background(item.imageURL)}} />
        );
    }

    let subtitleElem = [];
    if (item.hasOwnProperty("subtitle") && item.subtitle && item.subtitle.length > 0) {
        subtitleElem = (
            <div className={"support-page-item-content-subtitle"}>
                {item.subtitle}
            </div>
        );
    }

    const accessibilityString = Rosetta.string("main.accessibility_support_item", {
        title : item.title,
        subtitle : CommonUtil.getOrDefault(item, "subtitle", "")
    });

    return (
        <div
            className={"support-page-item"}
            tabIndex={0}
            aria-label={accessibilityString}
            role={"button"}
            onClick={() => {
                if (callback) {
                    callback("click", item)
                }
            }}>
            {imageElem}

            <div className={"support-page-item-content"}>
                <div className={"support-page-item-content-title"}>
                    {item.title}
                </div>
                {subtitleElem}
            </div>
        </div>
    );
}