import "./UIBlocker.css";
import {LoadingSpinner} from "../loading/LoadingSpinner";

export const UIBlocker = (props) => {

    const {blocked} = props;

    let overlayElem = [];
    let tabIndex = undefined;
    let ariaHidden = undefined;
    if (blocked) {
        overlayElem = (
            <div className={"blocker-overlay"}>
                <LoadingSpinner alignCentre={true}/>
            </div>
        );
        tabIndex = -1;
        ariaHidden = true;
    }

    return (
        <div
            className={"ui-blocker"}
            tabIndex={tabIndex}
            aria-hidden={ariaHidden}
        >

            {props.children}

            {overlayElem}

        </div>
    )

}