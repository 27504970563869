import {useEffect, useRef, useState} from "react";
import {API, ENDPOINTS} from "../../../network/API";
import axios from "axios";

import "./PodcastLandingScreen.css";
import backIcon from "../../../assets/chevron_back.svg";
import shareIcon from "../../../assets/share.svg";
import playIcon from "../../../assets/play.svg";
import {ImageUtil} from "../../../util/ImageUtil";
import Rosetta from "../../../rosetta/Rosetta";
import {Navigator} from "../../../navigator/Navigator";
import {PodcastPlayerScreen} from "./PodcastPlayerScreen";
import {PodcastUtil} from "../../../util/PodcastUtil";
import {ShareUtil} from "../../../util/ShareUtil";
import {ServiceLandingScreen} from "./ServiceLandingScreen";

import iconNextSteps from "../../../assets/ic_next_steps.svg";
import iconRead from "../../../assets/ic_read.svg";

export const PodcastLandingScreen = (props) => {

    const {podcastId} = props;

    const headerImageElem = useRef();

    const [podcast, setPodcast] = useState(null);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [headerImageWidth, setHeaderImageWidth] = useState(120);
    const [headerImageMargin, setHeaderImageMargin] = useState(-10);
    const [headerImageY, setHeaderImageY] = useState(0);
    const [headerImageAlpha, setHeaderImageAlpha] = useState(1);

    const focusableElem = useRef();

    useEffect(() => {
        if (podcastId) {
            fetchPodcastFromNetwork();
        }
    }, []);

    useEffect(() => {
        if (podcast) {
            setTimeout(() => {
                if (focusableElem.current) {
                    focusableElem.current.focus();
                }
            }, 50);
        }
    }, [podcast]);

    function contentDidScroll(e) {
        // Control header image position and opacity
        const scrollTop = e.target.scrollTop;
        if (headerImageElem) {
            const headerImageHeight = headerImageElem.current.offsetHeight;
            if (scrollTop < headerImageHeight) {
                let scrollProgress = scrollTop / headerImageHeight;
                let calcPercent = headerImageHeight * 0.1;
                let extraWidth = calcPercent * scrollProgress;

                let margin = -(calcPercent - extraWidth);

                setHeaderImageWidth(100 + (margin * -1));
                setHeaderImageMargin(margin / 2);
                setHeaderImageY(0);
                setHeaderImageAlpha(1);
            } else {
                let scrollProgress = scrollTop - headerImageHeight;
                setHeaderImageY((scrollProgress / 2) * -1);

                let alpha = headerImageHeight / scrollTop;
                setHeaderImageAlpha(alpha);
            }
        }
    }

    function moveToPlayer() {
        Navigator.navigate(
            <PodcastPlayerScreen
                podcastId={podcastId} />,
            {
                podcastId
            }
        );
    }

    function moveToPage(content) {
        Navigator.navigate(
            <ServiceLandingScreen content={content} />
        );
    }

    function fetchPodcastFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        PodcastUtil.getPodcast(podcastId, (action, data) => {
            if (action === "loaded") {
                setPodcast(data);
            } else if (action === "error") {
                console.log(data);
            }

            setNetworkInFlight(false);
        });

        // let url = ENDPOINTS.podcast.getPodcast + "?podcastID=" + podcastId;
        //
        // axios.get(url)
        //     .then((r) => {
        //         const resp = API.parse(r);
        //         if (resp.success) {
        //             if (resp.data.hasOwnProperty("podcast")) {
        //                 setPodcast(resp.data.podcast);
        //             } else {
        //                 console.log("Invalid response");
        //             }
        //         } else {
        //             console.log(API.formatError(resp));
        //         }
        //         setNetworkInFlight(false);
        //     })
        //     .catch((e) => {
        //         console.log(e);
        //         setNetworkInFlight(false);
        //     });
    }

    // RENDER

    let mainContent = [];

    if (podcast) {
        let podcastImageUrl = null;
        if (podcast.hasOwnProperty("headerImageURL")) {
            podcastImageUrl = podcast.headerImageURL;
        }

        const podcastContent = [];

        podcast.previewText.split("\n").forEach((line) => {
            podcastContent.push(
                <p>{line}</p>
            );
        });

        const options = [];

        if (podcast.hasOwnProperty("transcript") && podcast.transcript) {
            options.push(
                <PodcastOption
                    icon={iconRead}
                    label={Rosetta.string("podcast.option_transcript")}
                    callback={(action, data) => {
                        moveToPage(podcast.transcript)
                    }} />
            );
        }

        let useNextSteps = null;
        if (podcast.hasOwnProperty("nextStepsAlt") && podcast.nextStepsAlt) {
            useNextSteps = podcast.nextStepsAlt;
        // } else if (podcast.hasOwnProperty("nextSteps") && podcast.nextSteps) {
        //     useNextSteps = podcast.nextSteps;
        }

        if (useNextSteps) {
            options.push(
                <PodcastOption
                    icon={iconNextSteps}
                    label={Rosetta.string("podcast.option_next_steps")}
                    callback={(action, data) => {
                        if (action === "click") {
                            moveToPage(useNextSteps)
                        }
                    }} />
            );
        }

        let optionElem = [];
        if (options.length > 0) {
            if (options.length % 2 > 0) {
                options.push(<div className={"spacer"} />);
            }

            optionElem = (
                <div className={"podcast-options"}>
                    {options}
                </div>
            );
        }

        mainContent = (
            <>
                <div
                    className={"podcast-image"}
                    ref={headerImageElem}
                    style={{
                        width : headerImageWidth + "%",
                        marginLeft : headerImageMargin + "%",
                        top : headerImageY,
                        opacity : headerImageAlpha,
                        backgroundImage : ImageUtil.background(podcastImageUrl)
                    }}/>
                <div
                    className={"podcast-image-scrim"}
                    style={{
                        top: headerImageY
                    }}
                />

                <div className={"podcast-content"}>
                    <div className={"podcast-controls"}>
                        <div
                            className={"podcast-action-play"}
                            tabIndex={0}
                            aria-label={Rosetta.string("podcast.accessibility_play_button")}
                            role={"button"}
                            onClick={() => {
                                moveToPlayer();
                            }}
                        >
                            <div
                                className={"podcast-action-play-icon"}
                                tabIndex={-1}
                                aria-hidden={true}
                                style={{backgroundImage: ImageUtil.background(playIcon)}}
                            />

                            <div className={"podcast-action-play-text"} tabIndex={-1} aria-hidden={true}>
                                {Rosetta.string("podcast.action_play")}
                            </div>
                        </div>
                    </div>

                    <div className={"podcast-title"} tabIndex={0} autoFocus={true} ref={focusableElem} aria-level={1} role={"heading"}>{podcast.title}</div>
                    <div className={"podcast-subtitle"} tabIndex={0} role={"heading"} aria-level={2}>{podcast.categoryTitle}</div>

                    <div className={"podcast-text"}>
                        {podcastContent}
                    </div>

                    {optionElem}
                </div>
            </>
        )
    }

    /*
        <button
            className={"podcast-navigation-icon"}
            style={{backgroundImage: "url(" + backIcon + ")"}}
            onClick={() => {
                Navigator.back();
            }}
        />
     */

    return (
        <div className={"podcast-landing-screen"} onScroll={contentDidScroll}>
            {mainContent}
        </div>
    )

    /*
    <div className={"podcast-navigation"}>

                <span className={"spacer"} />

                <button
                    className={"podcast-navigation-icon"}
                    style={{backgroundImage : "url(" + shareIcon + ")"}}
                    onClick={() => {
                        ShareUtil.showShareModal(
                            "Share Test",
                            "https://www.explodingphone.com/"
                        )
                    }}
                />
            </div>
     */

}

const PodcastOption = (props) => {
    const {label} = props;
    const {icon} = props;
    const {callback} = props;

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    return (
        <div className={"podcast-option-wrapper"}>
            <div
                className={"podcast-option"}
                tabIndex={0}
                aria-label={label}
                role={"button"}
                onClick={() => handleCallback("click")}
            >
                <div className={"podcast-option-icon"} style={{backgroundImage: ImageUtil.background(icon)}}/>
                <div className={"podcast-option-text"}>
                    {label}
                </div>
            </div>
        </div>
    );

}