import Rosetta from "../../../rosetta/Rosetta";
import {ThemedButton} from "../../ui/ThemedButton";
import {useState} from "react";

import "./LinkFallbackScreen.css";

export const LinkFallbackScreen = (props) => {

    const CONTENT_MODE_URL = 1;
    const CONTENT_MODE_TEL = 2;
    const CONTENT_MODE_EMAIL = 3;

    const {url} = props;

    const [copied, setCopied] = useState(false);
    const [debug, setDebug] = useState(false);

    const [debugCount, setDebugCount] = useState(0);

    function copyUrl() {
        try {
            navigator.clipboard.writeText(url)
                .then((r) => {
                    setCopied(true);
                })
                .catch((e) => {
                    console.log(e);
                });
        } catch (e) {
            console.log(e);
        }
    }

    function debugIncrement() {
        if (!debug) {
            const newDebugCount = debugCount + 1;
            if (newDebugCount > 10) {
                setDebug(true);
            } else {
                setDebugCount(newDebugCount);
            }
        }
    }

    // RENDER

    let contentMode = CONTENT_MODE_URL;

    let contentLabel = url;
    if (contentLabel.startsWith("tel:")) {
        contentLabel = contentLabel.replaceAll("tel:", "");
        contentMode = CONTENT_MODE_TEL;
    } else if (contentLabel.startsWith("mailto:")) {
        contentLabel = contentLabel.replaceAll("mailto:", "");
        contentMode = CONTENT_MODE_EMAIL;
    }

    const contentLabelParts = contentLabel.split("?");
    if (contentLabelParts.length > 1) {
        contentLabel = contentLabelParts[0];
    }

    let browserName = Rosetta.string("link_fallback.browser_none");
    let tipsElems = [];

    const userAgent = window.navigator.userAgent;
    if (userAgent) {
        // Detect if we are running on an iOS device
        const appleUAs = ["iPod", "iPhone", "iPad"]; // , "Macintosh"
        let isAppleUA = false;
        appleUAs.forEach((ua) => {
            if (!isAppleUA) {
                isAppleUA = userAgent.includes(ua);
            }
        })

        if (isAppleUA || debug) {
            browserName = Rosetta.string("link_fallback.browser_safari");

            let tipType = Rosetta.string("link_fallback.tip_safari_type_url");
            let tipSubject = browserName;

            if (contentMode === CONTENT_MODE_TEL) {
                tipType = Rosetta.string("link_fallback.tip_safari_type_number");
                tipSubject = Rosetta.string("link_fallback.tip_safari_subject_dialer");
            } else if (contentMode === CONTENT_MODE_EMAIL) {
                tipType = Rosetta.string("link_fallback.tip_safari_type_email");
                tipSubject = Rosetta.string("link_fallback.tip_safari_subject_email");
            }

            tipsElems.push(
                <div className={"fallback-instructions"}>
                    <div className={"fallback-instructions-title"}>
                        {Rosetta.string("link_fallback.tip_title")}
                    </div>
                    <div className={"fallback-instructions-text"}>
                        {
                            Rosetta.string("link_fallback.tip_safari_long_press", {
                                type : tipType,
                                subject : tipSubject
                            })
                        }
                    </div>
                </div>
            )
        }
    }

    let pageTitle = Rosetta.string("link_fallback.open_in", {name : browserName});
    let copySubject = Rosetta.string("link_fallback.tip_safari_type_url");
    if (contentMode === CONTENT_MODE_TEL) {
        pageTitle = Rosetta.string("link_fallback.call_number");
        copySubject = Rosetta.string("link_fallback.tip_safari_type_number");
    } else if (contentMode === CONTENT_MODE_EMAIL) {
        pageTitle = Rosetta.string("link_fallback.send_email");
        copySubject = Rosetta.string("link_fallback.tip_safari_type_email");
    }

    let copyButtonLabel = Rosetta.string("link_fallback.copy_link", {type : copySubject});
    if (copied) {
        copyButtonLabel = Rosetta.string("link_fallback.copy_link_copied", {type: copySubject});
    }

    if (debug) {
        tipsElems.push(
            <div className={"fallback-instructions"}>
                <div className={"fallback-instructions-title"}>UA</div>
                <div className={"fallback-instructions-text"}>{userAgent}</div>
            </div>
        );
    }

    return (
        <div className={"link-fallback-screen"}>

            <div className={"spacer"} />

            <div className={"fallback-title"} onClick={debugIncrement}>
                {pageTitle}
            </div>

            <div className={"fallback-content"}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                    {contentLabel}
                </a>
            </div>

            <div className={"fallback-button"}>
                <ThemedButton
                    label={copyButtonLabel}
                    onClick={copyUrl}
                />
            </div>

            {tipsElems}

            <div className={"spacer"} />

        </div>
    )

}