import {AppConfig} from "./AppConfig";

export const DataManager = {
    _config : {
        useLocalStorage : false
    },
    _callbacks : [],
    _data : {},
    keys : {
        authToken : "uwbplugin_authtoken",
        refreshToken : "uwbplugin_refreshtoken",
        roleConfigured : "uwbplugin_roleconfigured",
        selectedEmotion : "uwbplugin_selectedemotion",
        roleCampusData : "uwbplugin_roleCampusData",
        usingLocalStorage : "uwbplugin_usingLocalStorage",
        welcomeScreenPassed : "uwbplugin_welcomeScreenPassed",
        user : "uwbplugin_user",
        temp_state : "uwbplugin_temp_state",
        podcastFeedbackIds : "uwbplugin_podcastFeedbackIds"
    },
    init : () => {
        const allowPersistence = parseInt(AppConfig.getConfig(AppConfig.keys.ALLOW_PERSISTENT_STORAGE, 0));
        DataManager._config.useLocalStorage = allowPersistence === 1;
        console.log("Allow persistence?", allowPersistence);
        if (parseInt(localStorage.getItem(DataManager.keys.usingLocalStorage)) === 1 && !allowPersistence) {
            console.log("Detected that this instance previously used local storage and config is now disallowed. Clearing localStorage...");
            DataManager.clear(false, true);
        }
        DataManager.setItem(DataManager.keys.usingLocalStorage, allowPersistence);
    },
    getAuthToken : () => {
        return DataManager.getItem(DataManager.keys.authToken);
    },
    setAuthToken : (token) => {
        return DataManager.setItem(DataManager.keys.authToken, token);
    },
    getRefreshToken : () => {
        return DataManager.getItem(DataManager.keys.refreshToken);
    },
    setRefreshToken : (token) => {
        return DataManager.setItem(DataManager.keys.refreshToken, token);
    },
    getRoleConfigured : () => {
        return parseInt(DataManager.getItem(DataManager.keys.roleConfigured)) === 1;
    },
    setRoleConfigured : (value) => {
        DataManager.setItem(DataManager.keys.roleConfigured, value ? 1 : 0);
    },
    getSelectedEmotion : () => {
        return DataManager.getItem(DataManager.keys.selectedEmotion);
    },
    setSelectedEmotion : (emotion) => {
        DataManager.setItem(DataManager.keys.selectedEmotion, emotion);
    },
    getRoleCampusData : () => {
        return DataManager.getPersistedJsonItem(DataManager.keys.roleCampusData, null);
    },
    setRoleCampusData : (data) => {
        return DataManager.persistJsonItem(DataManager.keys.roleCampusData, data);
    },
    isUsingLocalStorage : () => {
        return parseInt(DataManager.getItem(DataManager.keys.usingLocalStorage, 0)) === 1;
    },
    getWelcomeScreenPassed : () => {
        return parseInt(DataManager.getItem(DataManager.keys.welcomeScreenPassed, 0)) === 1;
    },
    setWelcomeScreenPassed : (welcomeScreenPassed) => {
        if (welcomeScreenPassed === true) {
            welcomeScreenPassed = 1;
        }
        return DataManager.setItem(DataManager.keys.welcomeScreenPassed, welcomeScreenPassed);
    },
    getUser : () => {
        return DataManager.getJsonItem(DataManager.keys.user, null);
    },
    setUser : (user) => {
        return DataManager.setJsonItem(DataManager.keys.user, user);
    },
    setTempState : (state) => {
        return DataManager.persistJsonItem(DataManager.keys.temp_state, state);
    },
    getTempState : () => {
        return DataManager.getPersistedJsonItem(DataManager.keys.temp_state, null);
    },
    setPodcastFeedbackIds : (feedbackIds) => {
        return DataManager.setJsonItem(DataManager.keys.podcastFeedbackIds, feedbackIds);
    },
    getPodcastFeedbackIds : () => {
        return DataManager.getJsonItem(DataManager.keys.podcastFeedbackIds, []);
    },
    getItem : (keyName, defaultValue) => {
        if (defaultValue === undefined) defaultValue = null;

        // console.log("Get item:" + keyName + " :: ULS? " + DataManager._config.useLocalStorage);

        let item = null;
        if (DataManager._config.useLocalStorage) {
            item = localStorage.getItem(keyName);
        } else {
            if (DataManager._data.hasOwnProperty(keyName)) {
                item = DataManager._data[keyName];
            }
        }

        if (item !== null) {
            return item;
        }
        return defaultValue;
    },
    setItem : (keyName, value) => {
        if (DataManager._config.useLocalStorage) {
            localStorage.setItem(keyName, value);
        } else {
            DataManager._data[keyName] = value;
        }

        DataManager._triggerCallbacks(keyName, value);
        return true;
    },
    getJsonItem : (keyName, defaultValue) => {
        let item = DataManager.getItem(keyName);
        if (item != null) {
            try {
                item = JSON.parse(item);
                return item;
            } catch (e) {
                console.log(e);
            }
        }

        if (defaultValue !== undefined) {
            return defaultValue;
        }
        return null;
    },
    setJsonItem : (keyName, item) => {
        return DataManager.setItem(keyName, JSON.stringify(item));
    },
    persistItem : (keyName, value) => {
        localStorage.setItem(keyName, value);
        DataManager._triggerCallbacks(keyName, value);
        return true;
    },
    persistJsonItem : (keyName, value) => {
        return DataManager.persistItem(keyName, JSON.stringify(value));
    },
    getPersistedItem : (keyName, defaultValue) => {
        const out = localStorage.getItem(keyName);
        if (out) {
            return out;
        }
        return defaultValue;
    },
    getPersistedJsonItem : (keyName, defaultValue) => {
        let value = DataManager.getPersistedItem(keyName);
        if (value) {
            try {
                return JSON.parse(value);
            } catch (e) {
                console.log(e);
            }
        }
        return defaultValue;
    },
    remove : (key) => {
        if (DataManager._config.useLocalStorage) {
            if (localStorage.getItem(key)) {
                localStorage.removeItem(key);
            }
        } else {
            if (DataManager._data.hasOwnProperty(key)) {
                delete DataManager._data[key];
            }
        }

        DataManager._triggerCallbacks(key, undefined);
        return true;
    },
    removePersisted : (key) => {
        return localStorage.removeItem(key);
    },
    getState : () => {
        if (!DataManager._config.useLocalStorage) {
            return DataManager._data;
        }
        return null;
    },
    setState : (state) => {
        // Take in state data
        DataManager._data = state;

        // Now notify all triggers to make sure things awaiting
        // are notified.
        const keys = Object.keys(DataManager._data);
        keys.forEach((key) => {
            DataManager._triggerCallbacks(
                key,
                DataManager._data[key]
            );
        });
    },
    clear : (triggerCallbacks, forceLocalStorage) => {
        if (triggerCallbacks === undefined) {
            triggerCallbacks = true;
        }

        if (forceLocalStorage === undefined) {
            forceLocalStorage = false;
        }

        for (const [key, value] of Object.entries(DataManager.keys)) {
            if (DataManager._config.useLocalStorage || forceLocalStorage) {
                console.log("Clear local storage: " + value);
                localStorage.removeItem(value);
            } else {
                delete DataManager._data[value];
            }

            if (triggerCallbacks) {
                DataManager._triggerCallbacks(value, undefined);
            }
        }

        return true;
    },
    addCallback : (callback) => {
        if (callback instanceof Function) {
            DataManager._callbacks.push(callback);
        }
    },
    removeCallback : (callback) => {
        for (let i = 0; i < DataManager._callbacks.length; i++) {
            if (DataManager._callbacks[i] === callback) {
                delete DataManager._callbacks[i];
                break;
            }
        }
    },
    _triggerCallbacks : (keyName, value) => {
        DataManager._callbacks.forEach((callback) => {
            callback(keyName, value);
        });
    }
}
