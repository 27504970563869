import {useEffect, useRef, useState} from "react";
import {API, ENDPOINTS} from "../../../network/API";
import axios from "axios";
import {CommonUtil} from "../../../util/CommonUtil";

import "./ServiceLandingScreen.css";

import chevron from "../../../assets/chevron_forward.svg";
import tick from "../../../assets/tick.svg";
import {ServiceUtil} from "../../../util/ServiceUtil";
import {ImageUtil} from "../../../util/ImageUtil";
import {Navigator} from "../../../navigator/Navigator";
import {PodcastLandingScreen} from "./PodcastLandingScreen";
import Rosetta from "../../../rosetta/Rosetta";

export const ServiceLandingScreen = (props) => {

    const {serviceId} = props;
    const {content} = props;

    const [service, setService] = useState(null);
    const [pageData, setPageData] = useState(null);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [inputData, setInputData] = useState({});

    useEffect(() => {
        if (serviceId) {
            fetchServiceFromNetwork();
        }
    }, []);

    useEffect(() => {
        if (content) {
            let useContent = content;
            if (!CommonUtil.isObject(content)) {
                useContent = JSON.parse(content);
            }
            setPageData(useContent);
        }
    }, [content]);

    useEffect(() => {
        if (serviceId) {
            fetchServiceFromNetwork();
        }
    }, [serviceId]);

    useEffect(() => {
        if (service) {
            setPageData(service.data);
        }
    }, [service]);

    function getInputData(key) {
        if (inputData.hasOwnProperty(key)) {
            return inputData[key];
        }
        return null;
    }

    function putInputData(key, data) {
        const newInputData = CommonUtil.cloneObject(inputData);
        newInputData[key] = data;
        setInputData(newInputData);
    }

    function handleCheckboxInput(data) {
        console.log("CHECKBOX CHANGE: ", data);

        let key = CommonUtil.getOrDefault(data, "key");
        let type = CommonUtil.getOrDefault(data, "type");
        let groupID = CommonUtil.getOrDefault(data, "groupID");

        const inputDataClone = CommonUtil.cloneObject(inputData);

        if (type === "single") {
            // If the checkbox is "single", uncheck all other items with
            // the same Group ID.
            let answerKeys = Object.keys(inputData);
            answerKeys.forEach((key) => {
                if (inputDataClone.hasOwnProperty(key)) {
                    let inputDataValue = inputDataClone[key];
                    if (inputDataValue.hasOwnProperty("groupID")) {
                        if (inputDataValue.groupID === groupID) {
                            delete inputDataClone[key];
                        }
                    }
                }
            });
        }

        // Query current value
        let currentValue = null;
        if (inputDataClone.hasOwnProperty(key)) {
            currentValue = inputDataClone[key];
        }

        if (!currentValue) {
            // If current value is null, create the value now
            currentValue = {
                key,
                groupID,
                value : true
            };
        } else {
            // Otherwise, toggle the value
            currentValue.value = !currentValue.value;
        }

        // Now set the value against the cloned input data and put into state
        inputDataClone[key] = currentValue;

        setInputData(inputDataClone);
    }

    function handleButtonPress(data) {
        if (data.hasOwnProperty("data")) {
            if (data.hasOwnProperty("type")) {
                if (data.type === "submit") {
                    console.log("Submit form.");
                    submitServiceResponseOverNetwork();
                } else if (data.type === "website") {
                    let url = CommonUtil.getOrDefault(data.data, "url");
                    if (url) {
                        CommonUtil.openWindow(url);
                    }
                } else if (data.type === "service") {
                    let serviceId = CommonUtil.getOrDefault(data.data, "serviceID");
                    console.log("WAS SERVICE: ", serviceId);
                    if (serviceId) {
                        ServiceUtil.resolveServiceAction(serviceId);
                    }
                } else if (data.type === "appLink") {
                    let androidUrl = CommonUtil.getOrDefault(data.data, "androidUrl");
                    if (androidUrl) {
                        CommonUtil.openWindow(androidUrl);
                    }
                } else if (data.type === "podcast") {
                    let podcastId = CommonUtil.getOrDefault(data.data, "podcastID");
                    if (podcastId) {
                        Navigator.navigate(
                            <PodcastLandingScreen podcastId={podcastId}/>
                        );
                    }
                } else if (data.type === "phone") {
                    let phoneNumber = CommonUtil.getOrDefault(data.data, "phoneNumber", null);
                    if (phoneNumber) {
                        CommonUtil.openWindow("tel:" + phoneNumber);
                    }
                } else if (data.type === "email") {
                    let email = CommonUtil.getOrDefault(data.data, "email");
                    if (email) {
                        CommonUtil.openWindow("mailto:" + email);
                    }
                }
            }
        }
    }

    function fetchServiceFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let url = ENDPOINTS.service.getService + "?serviceID=" + serviceId;

        axios.get(url)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setService(resp.data.service);
                } else {
                    console.log(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
            });
    }

    function submitServiceResponseOverNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const outData = {};

        let inputKeys = Object.keys(inputData);
        console.log(inputKeys);

        inputKeys.forEach((key) => {
            let value = inputData[key];
            if (CommonUtil.isObject(value)) {
                value = value.value;
            }

            outData[key] = value;
        });

        const formData = {
            serviceID : serviceId,
            convertToPDF : false,
            data : outData
        };

        axios.post(ENDPOINTS.service.submitResponse, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    console.log("Submitted!");
                } else {
                    console.log(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
            });
    }

    let serviceContent = [];
    let rootCssStyle = {};

    if (pageData) {
        if (pageData.hasOwnProperty("style")) {
            rootCssStyle = ServiceUtil.styleToCss(pageData.style, true);
        }

        if (pageData.hasOwnProperty("elements")) {
            let hasFoundAutoFocusTarget = false;

            pageData.elements.forEach((element, index) => {
                let data = CommonUtil.getOrDefault(element, "data", {});
                let style = CommonUtil.getOrDefault(element, "style", null);
                let cssStyle = null;
                if (style) {
                    cssStyle = ServiceUtil.styleToCss(style);
                }

                // Only apply autofocus to the first label in the UI
                let autoFocus = !hasFoundAutoFocusTarget;
                if (autoFocus) {
                    autoFocus = element.type === "label";
                    if (autoFocus) {
                        hasFoundAutoFocusTarget = true;
                    }
                }

                switch (element.type) {
                    case "label":
                        serviceContent.push(
                            <ServiceLabel
                                cssStyle={cssStyle}
                                text={CommonUtil.getOrDefault(data, "text")}
                                autoFocus={autoFocus}
                            />
                        );
                        break;
                    case "sectionHeader":
                    case "questionHeader":
                        serviceContent.push(
                            <ServiceHeader
                                key={CommonUtil.getOrDefault(data, "key")}
                                cssStyle={cssStyle}
                                leftText={CommonUtil.getOrDefault(data, "leftText")}
                                mainText={CommonUtil.getOrDefault(data, "mainText")}
                                autoFocus={autoFocus}
                            />
                        );

                        break;
                    case "checkbox":
                        let cbKey = CommonUtil.getOrDefault(data, "key");
                        let value = getInputData(cbKey);

                        serviceContent.push(
                            <ServiceCheckbox
                                dataKey={cbKey}
                                cssStyle={cssStyle}
                                text={CommonUtil.getOrDefault(data, "text")}
                                type={CommonUtil.getOrDefault(data, "type")}
                                groupID={CommonUtil.getOrDefault(data, "groupID")}
                                value={value}
                                autoFocus={autoFocus}
                                callback={(action, data) => {
                                    if (action === "change") {
                                        handleCheckboxInput(data);
                                    }
                                }}/>
                        )
                        break;
                    case "textView":
                        let tvKey = CommonUtil.getOrDefault(data, "key");
                        serviceContent.push(
                            <ServiceTextView
                                dataKey={tvKey}
                                cssStyle={cssStyle}
                                placeholder={CommonUtil.getOrDefault(data, "placeholder")}
                                value={getInputData(tvKey)}
                                autoFocus={autoFocus}
                                callback={(action, data) => {
                                    if (action === "change") {
                                        putInputData(tvKey, data);
                                    }
                                }}/>
                        );
                        break;
                    case "textField":
                        let tfKey = CommonUtil.getOrDefault(data, "key");

                        serviceContent.push(
                            <ServiceTextField
                                dataKey={tfKey}
                                cssStyle={cssStyle}
                                leftText={CommonUtil.getOrDefault(data, "leftText")}
                                placeholder={CommonUtil.getOrDefault(data, "placeholder")}
                                value={getInputData(CommonUtil.getOrDefault(data, "key"))}
                                autoFocus={autoFocus}
                                callback={(action, data) => {
                                    if (action === "change") {
                                        setInputData(tfKey, data);
                                    }
                                }}/>
                        );
                        break;
                    case "button":
                        serviceContent.push(
                            <ServiceButton
                                cssStyle={cssStyle}
                                title={CommonUtil.getOrDefault(data, "title")}
                                action={CommonUtil.getOrDefault(data, "action")}
                                autoFocus={autoFocus}
                                callback={(action, data) => {
                                    if (action === "click") {
                                        handleButtonPress(data);
                                    }
                                }}/>
                        );
                        break;
                    case "tableItem":
                        const action = CommonUtil.getOrDefault(data, "action", {});

                        serviceContent.push(
                            <ServiceTableItem
                                cssStyle={cssStyle}
                                title={CommonUtil.getOrDefault(data, "title")}
                                subtitle={CommonUtil.getOrDefault(data, "subtitle")}
                                data={action}
                                autoFocus={autoFocus}
                                callback={(action, data) => {
                                    if (action === "click") {
                                        handleButtonPress(data);
                                    }
                                }}
                            />
                        );
                        break;
                    case "image":
                        serviceContent.push(
                            <ServiceImage
                                cssStyle={cssStyle}
                                imageURL={CommonUtil.getOrDefault(data, "url")}
                                aspectRatio={CommonUtil.getOrDefault(data, "aspectRatio")}
                                contentMode={CommonUtil.getOrDefault(data, "contentMode")}
                                autoFocus={autoFocus}
                            />
                        );
                        break;
                    default:
                        serviceContent.push(
                            <div>Unsupported type: {element.type}</div>
                        );
                }
            });
        }
    }

    return (
        <div className={"service-landing-screen"} style={rootCssStyle}>
            {serviceContent}
        </div>
    )

}

const ServiceLabel = (props) => {

    const {text} = props;
    const {cssStyle} = props;
    const {autoFocus} = props;

    const focusableElem = useRef();

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                if (focusableElem.current) {
                    focusableElem.current.focus();
                }
            }, 50);
        }
    }, []);

    return (
        <div
            className={"service-label"}
            tabIndex={0}
            aria-label={text}
            role={"presentation"}
            style={cssStyle}
            autoFocus={autoFocus}
            ref={focusableElem}
        >
            {text.split("\n").map((line) => (
                <p>{line}</p>
            ))}
        </div>
    );
}

const ServiceHeader = (props) => {

    const {key} = props;
    const {leftText} = props;
    const {mainText} = props;
    const {cssStyle} = props;
    const {autoFocus} = props;

    const focusableElem = useRef();

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                if (focusableElem.current) {
                    focusableElem.current.focus();
                }
            }, 50)
        }
    }, []);

    let leftTextElem = [];
    if (leftText) {
        leftTextElem = (
            <div className={"service-header-left-text"}>
                {leftText}
            </div>
        );
    }

    let accessibilityLabel = mainText;
    if (leftText) {
        accessibilityLabel = leftText + accessibilityLabel;
    }

    return (
        <div
            className={"service-header"}
            style={cssStyle}
            autoFocus={autoFocus}
            aria-label={accessibilityLabel}
            role={"presentation"}
            ref={focusableElem}
        >
            {leftTextElem}
            <div className={"service-header-main-text"}>
                {mainText}
            </div>
        </div>
    );

}

const ServiceCheckbox = (props) => {

    const {cssStyle} = props;
    const {dataKey} = props;
    const {text} = props;
    const {type} = props;
    const {groupID} = props;
    const {autoFocus} = props;

    const {value} = props;
    const {callback} = props;

    const focusableElem = useRef();

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                focusableElem.current.focus();
            }, 50);
        }
    }, []);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    // RENDER

    let tickGraphic = null;
    if (value) {
        if (value.hasOwnProperty("value")) {
            if (value.value === true) {
                tickGraphic = tick;
            }
        }
    }

    return (
        <div
            className={"service-checkbox"}
            style={cssStyle}
            autoFocus={autoFocus}
            tabIndex={0}
            onClick={() => handleCallback("change", {
                key : dataKey,
                type,
                groupID
            })}
        >
            <div className={"service-checkbox-tick"} style={{backgroundImage : "url(" + tickGraphic + ")"}} />
            <div className={"service-checkbox-content"}>
                <div className={"service-checkbox-title"}>
                    {text}
                </div>
            </div>
        </div>
    );

}

const ServiceTextView = (props) => {

    const {cssStyle} = props;
    const {dataKey} = props;
    const {placeholder} = props;
    const {autoFocus} = props;

    const {value} = props;
    const {callback} = props;

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    return (
        <div className={"service-text-field"} style={cssStyle}>
            <textarea
                className={"tall"}
                placeholder={placeholder}
                value={value}
                autoFocus={autoFocus}
                onChange={(e) => {
                    handleCallback("change", e.target.value)
                }}
            />
        </div>
    );

}

const ServiceTextField = (props) => {

    const {key} = props;
    const {cssStyle} = props;
    const {leftText} = props;
    const {placeholder} = props;
    const {autoFocus} = props;

    const {value} = props;
    const {callback} = props;

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    // RENDER

    let leftTextElem = [];
    if (leftText && leftText !== "") {
        leftTextElem = (
            <div className={"service-text-field-left-text"}>
                {leftText}
            </div>
        );
    }

    return (
        <div className={"service-text-field"} style={cssStyle}>
            <textarea
                placeholder={placeholder}
                value={value}
                autoFocus={autoFocus}
                onChange={(e) => {
                    handleCallback("change", {
                        key,
                        value : e.target.value
                    })
                }}/>
            {leftTextElem}
        </div>
    );

}

const ServiceButton = (props) => {

    const {cssStyle} = props;
    const {title} = props;
    const {action} = props;
    const {callback} = props;
    const {autoFocus} = props;

    const focusableElem = useRef();

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                if (focusableElem.current) {
                    focusableElem.current.focus();
                }
            }, 50);
        }
    }, []);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    let labelExtra = "";
    if (action) {
        if (action.type === "phone") {
            let phoneNumber = CommonUtil.getOrDefault(action.data, "phoneNumber", null);
            if (phoneNumber) {
                labelExtra += ": " + phoneNumber;
            }
        }
    }

    const labelComplete = title + labelExtra;

    return (
        <div className={"service-button-container"} style={cssStyle}>
            <button
                className={"service-button"}
                tabIndex={0}
                aria-label={labelComplete}
                role={"button"}
                autoFocus={autoFocus}
                ref={focusableElem}
                onClick={() => {
                    handleCallback("click", action);
                }}>

                {labelComplete}

            </button>
        </div>
    )
}

const ServiceTableItem = (props) => {

    const {cssStyle} = props;
    const {title} = props;
    const {subtitle} = props;
    const {data} = props;
    const {callback} = props;
    const {autoFocus} = props;

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    const ariaLabel = title + " " + subtitle;

    return (
        <div
            className={"service-table-item"}
            style={cssStyle}
            autoFocus={autoFocus}
            tabIndex={0}
            aria-label={ariaLabel}
            role={"presentation"}
            onClick={() => handleCallback("click", data)}
        >
            <div className={"service-table-item-content"}>
                <div className={"service-table-item-content-title"}>{title}</div>
                <div className={"service-table-item-content-subtitle"}>{subtitle}</div>
            </div>
            <div className={"service-table-item-chevron"} style={{backgroundImage : "url(" + chevron + ")"}} />
        </div>
    )

}

const ServiceImage = (props) => {

    const {cssStyle} = props;
    const {imageURL} = props;
    const {altText} = props;
    const {aspectRatio} = props;
    const {contentMode} = props;
    const {autoFocus} = props;

    // RENDER

    const useImageUrl = API.getAPIUrl(imageURL);

    let imageElem = [];
    let overlayElem = [];

    if (aspectRatio) {
        // If a ratio is set, do not use the <img> tag, instead use a
        // div and set the image as the background. This allows us to
        // fit and crop more flexibly.
        let classExtra = "";
        if (contentMode) {
            if (contentMode.toLowerCase() === "cover") {
                classExtra = " cover";
            }
        }

        imageElem = (
            <div
                className={"ratio-image" + classExtra}
                style={{
                    paddingTop : (aspectRatio * 100) + "%",
                    backgroundImage : ImageUtil.background(useImageUrl)
                }} />
        )
    } else {
        let imageExtraClass = "";
        if (contentMode) {
            // If a content mode is set, hide the original <img> element
            // but only by changing the opacity. We will use the image's
            // inherent height to allow us to use other content modes
            // even though we do not inherently have a size for the image
            // to occupy.
            imageExtraClass = " hide";

            overlayElem = (
                <div
                    className={"image-overlay"}
                    style={{backgroundImage : ImageUtil.background(useImageUrl)}}
                />
            );
        }

        imageElem = (
            <img
                className={imageExtraClass}
                src={useImageUrl}
                alt={altText}
                tabIndex={0}
                autoFocus={autoFocus}
            />
        )
    }

    return (
        <div className={"service-image"} style={cssStyle}>
            {imageElem}
            {overlayElem}
        </div>
    )

}