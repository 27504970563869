import axios from "axios";
import {API, ENDPOINTS} from "../network/API";
import {Navigator} from "../navigator/Navigator";
import {ServiceLandingScreen} from "../components/screens/service/ServiceLandingScreen";
import {PodcastLandingScreen} from "../components/screens/service/PodcastLandingScreen";
import {HereNowLandingScreen} from "../components/screens/service/HereNowLandingScreen";
import {BellyBreathingLandingScreen} from "../components/screens/service/BellyBreathingLandingScreen";
import {FourSevenEightBreathingLandingScreen} from "../components/screens/service/FourSevenEightBreathingLandingScreen";
import {BreathingLandingScreen} from "../components/screens/service/BreathingLandingScreen";
import {PomodoroLandingScreen} from "../components/screens/service/PomodoroLandingScreen";
import {UnimplementedDeadendScreen} from "../components/screens/service/UnimplementedDeadendScreen";
import {CommonUtil} from "./CommonUtil";
import {DataManager} from "./DataManager";
import {RoleLocationSelectionScreen} from "../components/screens/user/RoleLocationSelectionScreen";
import {AuthUtil} from "./AuthUtil";

export const ServiceUtil = {
    _temporaryServiceData : null,
    serviceTypes : {
        NATIVE_FEATURE : 1,
        WEBSITE : 2,
        PODCAST : 3,
        PAGE : 4,
        CHALLENGE : 5,
        VIDEO : 6,
        HABIT : 7,
        ASSESSMENT : 8,
        SSO_LOGIN : 9
    },
    supportServices : {
        POMODORO : 1,
        BREATHING_CONTROL : 2,
        FEATURE_HERE_AND_NOW : 3,
        BELLY_BREATHING : 4,
        FOUR_SEVEN_EIGHT_BREATHING : 5
    },
    resolveServiceAction : (service, callback, popFirst) => {
        if (popFirst === undefined) {
            popFirst = false;
        }

        if (!service) {
            console.log("ERROR! Service was bad!" , service);
            return;
        }

        if (!isNaN(service)) {
            console.log("Identified Service ID. Resolving...");
            ServiceUtil.fetchServiceFromNetwork(service, (action, data) => {
                if (action === "load" && data && data.hasOwnProperty("service")) {
                    ServiceUtil.resolveServiceAction(data.service, callback);
                } else {
                    if (callback) {
                        callback(action, data);
                    }
                }
            });
        } else {
            let serviceDidResolve = false;
            if (service.hasOwnProperty("typeID")) {
                const typeId = parseInt(service.typeID);
                let serviceData = null;
                if (service.hasOwnProperty("data")) {
                    serviceData = service.data;
                }

                if (typeId === ServiceUtil.serviceTypes.NATIVE_FEATURE) {
                    if (serviceData && serviceData.hasOwnProperty("featureID")) {
                        Navigator.navigate(<UnimplementedDeadendScreen />, undefined, popFirst);
                        serviceDidResolve = true;

                        // const featureId = parseInt(serviceData.featureID);
                        //
                        // switch (featureId) {
                        //     case ServiceUtil.supportServices.POMODORO:
                        //         Navigator.navigate(
                        //             <PomodoroLandingScreen />
                        //         );
                        //         break;
                        //     case ServiceUtil.supportServices.BREATHING_CONTROL:
                        //         Navigator.navigate(
                        //             <BreathingLandingScreen />
                        //         );
                        //         break;
                        //     case ServiceUtil.supportServices.FEATURE_HERE_AND_NOW:
                        //         Navigator.navigate(
                        //             <HereNowLandingScreen />
                        //         );
                        //         break;
                        //     case ServiceUtil.supportServices.BELLY_BREATHING:
                        //         Navigator.navigate(
                        //             <BellyBreathingLandingScreen />
                        //         );
                        //         break;
                        //     case ServiceUtil.supportServices.FOUR_SEVEN_EIGHT_BREATHING:
                        //         Navigator.navigate(
                        //             <FourSevenEightBreathingLandingScreen />
                        //         );
                        //         break;
                        //     default:
                        //         console.log("Unsupported feature ID: " + featureId);
                        // }
                    }
                } else if (typeId === ServiceUtil.serviceTypes.WEBSITE) {
                    if (serviceData && serviceData.hasOwnProperty("url")) {
                        const url = serviceData.url;
                        //window.open(url);
                        CommonUtil.openWindow(url);
                    }
                } else if (typeId === ServiceUtil.serviceTypes.PODCAST) {
                    if (serviceData && serviceData.hasOwnProperty("podcastID")) {
                        const podcastId = parseInt(serviceData.podcastID);

                        if (podcastId > 0) {
                            Navigator.navigate(
                                <PodcastLandingScreen
                                    podcastId={podcastId} />,
                                {
                                    podcastId
                                },
                                undefined,
                                popFirst
                            );

                            serviceDidResolve = true;
                        }
                    }
                } else if (typeId === ServiceUtil.serviceTypes.PAGE) {
                    Navigator.navigate(
                        <ServiceLandingScreen
                            serviceId={service.id}
                            service={service} />,
                        {
                            service
                        },
                        popFirst
                    );

                    serviceDidResolve = true;
                } else if (typeId === ServiceUtil.serviceTypes.CHALLENGE) {
                    Navigator.navigate(<UnimplementedDeadendScreen />, undefined, popFirst);
                    serviceDidResolve = true;

                    // if (serviceData && serviceData.hasOwnProperty("id")) {
                    //     const challengeId = parseInt(serviceData.id);
                    //     // Navigate to challenge
                    //     serviceDidResolve = true;
                    // }
                } else if (typeId === ServiceUtil.serviceTypes.VIDEO) {
                    if (serviceData && serviceData.hasOwnProperty("url")) {
                        if (serviceData.url) {
                            //window.open(serviceData.url);
                            CommonUtil.openWindow(serviceData.url);
                        }
                    }
                    serviceDidResolve = true;
                } else if (typeId === ServiceUtil.serviceTypes.HABIT) {
                    Navigator.navigate(<UnimplementedDeadendScreen />, undefined, popFirst);
                    serviceDidResolve = true;

                    // if (serviceData && serviceData.hasOwnProperty("id")) {
                    //     const habitId = parseInt(serviceData.id);
                    //
                    //     if (habitId > 0) {
                    //         // Navigate to Habit
                    //     }
                    //
                    //     serviceDidResolve = true;
                    // }
                } else if (typeId === ServiceUtil.serviceTypes.ASSESSMENT) {
                    Navigator.navigate(<UnimplementedDeadendScreen />, undefined, popFirst);
                    serviceDidResolve = true;

                    // if (serviceData && serviceData.hasOwnProperty("id")) {
                    //     const assessmentId = parseInt(serviceData.id);
                    //     if (assessmentId > 0) {
                    //         // Navigate to Assessment
                    //     }
                    //
                    //     serviceDidResolve = true;
                    // }
                } else if (typeId === ServiceUtil.serviceTypes.SSO_LOGIN) {
                    AuthUtil.startSSOLogin();
                }
            }

            if (!serviceDidResolve) {
                if (callback) {
                    callback("error", "Unable to resolve Service");
                }
            }
        }
    },
    fetchServiceFromNetwork : (serviceId, callback) => {
        let url = ENDPOINTS.service.getService + "?serviceID=" + serviceId;

        axios.get(url)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (callback) {
                        callback("load", resp.data);
                    }
                } else {
                    if (callback) {
                        callback("error", API.formatError(resp));
                    }
                }
            })
            .catch((e) => {
                console.log(e);
                if (callback) {
                    callback("error", e.getMessage());
                }
            });
    },
    styleToCss : (style, preferPadding) => {
        const out = {};

        if (style) {
            if (style.hasOwnProperty("font")) {
                let fontName = CommonUtil.getOrDefault(style.font, "name", null);
                if (fontName) {
                    out.fontFamily = fontName + ", sans-serif";
                }

                let fontSize = CommonUtil.getOrDefault(style.font, "size", null);
                if (fontSize) {
                    out.fontSize = fontSize + "px";
                }

                let fontType = CommonUtil.getOrDefault(style.font, "type", null);
                let fontVariant = CommonUtil.getOrDefault(style.font, "variant", null);
                if (fontType) {
                    if (fontType.toLowerCase() === "primary") {
                        let fontStyle = "barlow-condensed";

                        if (fontVariant) {
                            switch (fontVariant.toLowerCase()) {
                                case "bold":
                                    fontStyle = "barlow-condensed-bold"; break;
                                case "semibold":
                                    fontStyle = "barlow-condensed-semibold"; break;
                                default:
                                    console.log("Unknown font variant: ", fontVariant);
                            }
                        }

                        out.fontFamily = fontStyle + ", sans-serif";
                    } else {
                        if (fontVariant) {
                            switch (fontVariant.toLowerCase()) {
                                case "bold":
                                    out.fontWeight = "bold"; break;
                                case "boldItalic":
                                    out.fontWeight = "bold";
                                    out.fontStyle = "italic";
                                    break;
                                case "regularItalic":
                                    out.fontStyle = "italic"; break;
                                case "light":
                                    out.fontWeight = "light"; break;
                                case "lightItalic":
                                    out.fontWeight = "light";
                                    out.fontStyle = "italic";
                                    break;
                                default:
                                    console.log("Unknown font variant: ", fontVariant);
                            }
                        }
                    }
                }

                let fontColour = CommonUtil.getOrDefault(style.font, "color", null);
                if (fontColour) {
                    out.color = fontColour;
                }
            }

            if (style.hasOwnProperty("margins")) {
                let propPrefix = "margin";
                if (preferPadding !== undefined && preferPadding === true) {
                    propPrefix = "padding";
                }

                const marginProps = [
                    { key : "top", propertyName : propPrefix + "Top", suffix : "px" },
                    { key : "left", propertyName : propPrefix + "Left", suffix : "px" },
                    { key : "right", propertyName : propPrefix + "Right", suffix : "px" },
                    { key : "bottom", propertyName : propPrefix + "Bottom", suffix : "px" },
                ];

                marginProps.forEach((prop) => {
                    if (style.margins.hasOwnProperty(prop.key)) {
                        let value = style.margins[prop.key];
                        if (value) {
                            out[prop.propertyName] = value + CommonUtil.getOrDefault(prop, "suffix", "");
                        }
                    }
                });
            }
        }

        return out;
    }
}