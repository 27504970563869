export const UrlUtil = {
    _queryParamCache : null,
    getQueryParameters : () => {
        let out = {};

        const urlParts = window.location.href.split("?");
        if (urlParts.length > 1) {
            const queryParts = urlParts[1].split("&");
            for (let i = 0; i < queryParts.length; i++) {
                const dataSplit = queryParts[i].split("=");
                let key = dataSplit[0];
                let value = null;
                if (dataSplit.length > 1) {
                    value = dataSplit[1];
                }
                out[key] = value;
            }
        }

        return out;
    },
    getQueryParameter : (key, defaultValue) => {
        if (UrlUtil._queryParamCache === null) {
            UrlUtil._queryParamCache = UrlUtil.getQueryParameters();
        }

        if (UrlUtil._queryParamCache) {
            if (UrlUtil._queryParamCache.hasOwnProperty(key)) {
                return UrlUtil._queryParamCache[key];
            }
        }

        return defaultValue;
    }
}