import "./LoadingSpinner.css";

export const LoadingSpinner = (props) => {

    const {alignCentre} = props;
    const {block} = props;

    let classExtra = "";
    if (block) {
        classExtra += " block";
    }

    let mainContent = (
        <div className={"loading-spinner" + classExtra}>
            <div className={"loading-spinner-indicator"}/>
        </div>
    );

    if (alignCentre) {
        mainContent = (
            <div className={"loading-spinner-container"}>
                {mainContent}
            </div>
        );
    }

    return mainContent;

}