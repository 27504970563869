import {useEffect, useRef, useState} from "react";

import "./ShareModal.css";
import {ImageUtil} from "../../util/ImageUtil";
import Rosetta from "../../rosetta/Rosetta";

import iconLink from "../../assets/link.svg";
import iconCopy from "../../assets/copy.svg";
import iconEmail from "../../assets/email.svg";
import iconFacebook from "../../assets/share_facebook.svg";

export const ShareModalActions = {
    DISMISS : "sharemodal.dismiss"
};

export const ShareModal = (props) => {

    const {shown} = props;
    const {title} = props;
    const {shareUrl} = props;
    const {callback} = props;

    const [dismissing, setDismissing] = useState(false);

    const dismissTimeout = useRef();

    useEffect(() => {
        if (!shown) {
            clearTimeout(dismissTimeout.current);
            setDismissing(false);
        }
    }, [shown]);

    useEffect(() => {
        if (dismissing) {
            dismissTimeout.current = setTimeout(() => {
                if (callback) {
                    callback(ShareModalActions.DISMISS);
                }
            }, 200);
        }
    }, [dismissing]);

    if (!shown) return [];

    const shareOptions = [
        {
            icon : iconEmail,
            label : Rosetta.string("share.email"),
            action : () => {
                window.open("mailto:?body=" + title);
            }
        },
        {
            icon : iconFacebook,
            label : Rosetta.string("share.facebook"),
            action : () => {
                window.open("https://www.facebook.com/sharer/sharer.php?u=" + shareUrl);
            }
        }
    ]

    let modalKey = "sharemodal";
    let dialogKey = "sharemodaldialog";
    let extraClass = "";

    if (dismissing) {
        modalKey += "_dismissing";
        dialogKey += "_dismissing";
        extraClass = " dismissing";
    }

    return (
        <div className={"share-modal" + extraClass} key={modalKey}>
            <div className={"top-spacer"} onClick={() => setDismissing(true)} />

            <div className={"share-modal-dialog"} key={dialogKey}>
                <div className={"share-modal-dialog-header"}>
                    <div className={"share-modal-dialog-icon"} style={{backgroundImage: ImageUtil.background(iconLink)}}/>
                    <div className={"share-modal-dialog-header-content"}>
                        <div className={"header-content-title"}>
                            {title}
                        </div>
                    </div>
                    <div className={"share-modal-dialog-icon"} style={{backgroundImage: ImageUtil.background(iconCopy)}}/>
                </div>

                <div className={"share-modal-dialog-body"}>
                    {
                        shareOptions.map((item) => (
                            <div className={"share-item"} onClick={() => { if (item.action) item.action() }}>
                                <div className={"share-item-icon"}>
                                    <div className={"share-modal-dialog-icon"} style={{backgroundImage: ImageUtil.background(item.icon)}}/>
                                </div>
                                <div className={"share-item-label"}>
                                    {item.label}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );

}