export const MainErrorScreen = (props) => {

    const {errorMessage} = props;

    return (
        <div className={"app-config-blocker"}>
            <div className={"error-message"}>
                {errorMessage}
            </div>
        </div>
    );

}